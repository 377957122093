const pt = {
  TeacherReport: {
    Title: "Progresso do módulo",
    Students: "Estudantes",
    Total: "Total",
    HeaderTest: "Teste",
    HeaderNormal: "Aprendizado",
    HeaderCompleted: "Concluído",
    HeaderResult: "Resultado",
    Blocks: "Blocos",
    StudentSelect: "Selecione o estudante",
    DeleteReport: "Tem certeza?",
    DeleteTooltip: "Excluir",
    DownloadTooltip: "Baixar",
  },
  BlockTypes: {
    Vocabulary: "Vocabulário",
    Video: "Vídeo",
    Grammar: "Gramática",
    Reading: "Leitura",
    Writting: "Escrita",
    Speaking: "Conversação",
    Test: "Teste",
  },
  Loading: {
    Error: "Erro ao carregar, tente atualizar a página",
  },
  SteppedProgress: {
    Completed: "Concluído",
  },
};

export default pt;
