import { useEffect, useState } from "react";

import { Pagination } from "@jetspeak/jetclass-ui-lib";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { Lesson } from "@packages/store/models/Lesson/LessonModel";
import { PivotReportStudent } from "@packages/store/services/Api";

import styles from "./AttendancePivotTable.module.scss";
import { AttendancePivotTableComment } from "./AttendancePivotTableComment";
import { TableCell } from "./styledComponents/TableCell";
import { Progress } from "../../components/Progress";
import {
  getPivotTableMonths,
  getPivotStudentAttendance,
  getPivotStudentLessonHours,
} from "../../helpers";

export interface AttendancePivotTableProps {
  students: PivotReportStudent[];
  lessons: Lesson[];
  perPage?: number;
}

export const AttendancePivotTable = ({
  students,
  lessons,
  perPage = 11,
}: AttendancePivotTableProps): JSX.Element | null => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [currentLessons, setCurrentLessons] = useState<Lesson[]>([]);

  const pagesCount = Math.ceil(lessons.length / perPage);

  useEffect(() => {
    const from = (page - 1) * perPage;
    const to = from + perPage;
    setCurrentLessons(lessons.slice(from, to));
  }, [setCurrentLessons, page, lessons, perPage]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const getAllStudentActualHours = (ids: number[]) => {
    return lessons.reduce((acc, lesson) => {
      const attendance = getPivotStudentAttendance(ids, lesson);
      const { compensation } = lesson;
      if (!attendance || compensation) {
        return acc;
      }
      const hours = lesson.exactDuration ?? 0;
      return acc + hours;
    }, 0);
  };

  const getLessonDuration = (lesson: Lesson) => {
    const { compensation } = lesson;
    const duration = compensation ? 0 : lesson.exactDuration ?? 0;
    return duration.toFixed(2);
  };

  const getStudentVisitedHours = (ids: number[]) => {
    return lessons.reduce((acc, lesson) => {
      const hours = +getPivotStudentLessonHours(ids, lesson);
      if (!hours || Number.isNaN(hours)) {
        return acc;
      }
      return acc + hours;
    }, 0);
  };

  const getLessonDurationColor = (lesson: Lesson) => {
    const { compensation } = lesson;
    if (compensation) {
      return "#5783F1";
    }
    return "#000000";
  };

  const getStudentLessonHoursColor = (ids: number[], lesson: Lesson) => {
    const hours = getPivotStudentLessonHours(ids, lesson);
    if (hours === "X" || hours === "ⓧ") {
      return "#FF9A03";
    }
    const { compensation } = lesson;
    if (compensation) {
      return "#5783F1";
    }
    const attendance = getPivotStudentAttendance(ids, lesson);
    if (!attendance) {
      return "#FF9A03";
    }
    if (!attendance.attended || lesson.compensation) {
      return "#FF2E00";
    }
    return "black";
  };

  const months = getPivotTableMonths(
    currentLessons.map((lesson) => lesson.day) as Date[]
  );

  return (
    <div className={styles.AttendancePivotTable}>
      <TableContainer>
        <Table
          sx={{
            borderCollapse: "separate",
          }}
        >
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
            }}
          >
            <TableRow
              sx={{
                backgroundColor: "#E8F8E4",
              }}
            >
              <TableCell>{t("AttendancePivotTable:Student")}</TableCell>
              <TableCell>{t("AttendancePivotTable:Visited")}</TableCell>
              <TableCell colSpan={currentLessons.length} align="center">
                {t("AttendancePivotTable:Hours")}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#E8F8E4",
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                {t("AttendancePivotTable:Month")}
              </TableCell>
              {months.map((item) => (
                <TableCell
                  colSpan={item.columns}
                  align="center"
                  key={`month-item-${item.month}-${item.year}`}
                  sx={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {t(`MonthName:${item.month}`)}, {item.year}
                </TableCell>
              ))}
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#F4F4F4",
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                {t("AttendancePivotTable:Date")}
              </TableCell>
              {currentLessons.map((lesson) => (
                <TableCell
                  key={`lesson-day-${lesson.id}`}
                  align="center"
                  sx={{ position: "relative" }}
                >
                  {lesson.day?.getDate()}
                  <AttendancePivotTableComment comment={lesson.comment} />
                </TableCell>
              ))}
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#F4F4F4",
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                {t("AttendancePivotTable:Lesson")}
              </TableCell>
              {currentLessons.map((lesson) => (
                <TableCell key={`lesson-index-${lesson.id}`} align="center">
                  {lesson.index}
                </TableCell>
              ))}
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#F4F4F4",
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                {t("AttendancePivotTable:Duration")}
              </TableCell>
              {currentLessons.map((lesson) => (
                <TableCell
                  key={`lesson-duration-${lesson.id}`}
                  align="center"
                  sx={{
                    color: getLessonDurationColor(lesson),
                  }}
                >
                  {getLessonDuration(lesson)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student) => (
              <TableRow key={`student-${student.ids[0]}`}>
                <TableCell
                  sx={{
                    backgroundColor: "#F4F4F4",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {student.name}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#F4F4F4",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <Progress
                    steps={getAllStudentActualHours(student.ids)}
                    value={getStudentVisitedHours(student.ids)}
                    colorSteps="green"
                    type="attendance"
                  />
                </TableCell>
                {currentLessons.map((lesson) => (
                  <TableCell align="center" key={lesson.id}>
                    <Typography
                      fontSize="0.8rem"
                      color={getStudentLessonHoursColor(student.ids, lesson)}
                      key={`lesson-hours-${lesson.id}`}
                      translate="no"
                    >
                      {getPivotStudentLessonHours(student.ids, lesson)}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagesCount > 1 && (
        <Box py="1rem" justifyContent="center" display="flex" bgcolor="#F4F4F4">
          <Pagination
            currPage={page}
            total={lessons.length}
            perPage={perPage}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </div>
  );
};
