import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { BlockModel, BlockSnapshot } from "./Block";

/**
 * Model description here for TypeScript hints.
 */
export const BlockStoreModel = types
  .model("BlockStore")
  .props({
    items: types.optional(types.array(BlockModel), []),
  })
  .actions((self) => ({
    addItem: (item: BlockSnapshot) => {
      const instance = resolveIdentifier(BlockModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }));

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type BlockStoreType = Instance<typeof BlockStoreModel>;
export type BlockStore = BlockStoreType;
type BlockStoreSnapshotType = SnapshotOut<typeof BlockStoreModel>;
export type BlockStoreSnapshot = BlockStoreSnapshotType;
