import { useState, useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import {
  UpdateCreativeTaskParams,
  useGetCreativeTask,
  useUpdateCreativeTask,
} from "@packages/data/creativeTasks";

import { AnswerWrapper } from "./AnswerWrapper/AnswerWrapper";
import { CheckingHistory } from "./CheckingHistory";
import styles from "./TaskDetails.module.scss";
import { useUser } from "../../hooks";
import { formatTime } from "../../utils/helpers";
import { Icon } from "../Icon";
import { Loading } from "../Loading";
import { Modal } from "../Modal";
import { Notification, NotificationType } from "../Notification";
import { Text } from "../Text";

interface TaskDetailsProps {
  id: string | undefined;
  onClose?: () => void;
}

export const TaskDetails = observer((props: TaskDetailsProps) => {
  const { id, onClose } = props;

  const isOpen = Boolean(id);

  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<NotificationType>("info");

  const { isTeacher } = useUser();

  const { data, isLoading } = useGetCreativeTask(
    {
      id: id as string,
      role: isTeacher ? "teacher" : "student",
    },
    {
      enabled: isOpen,
    }
  );

  const { mutate: updateCreativeTask, isPending } = useUpdateCreativeTask();

  const handleMessageClear = () => {
    setMessage("");
  };

  const showMessageWithType = (message: string, type: NotificationType) => {
    setMessage(message);
    setMessageType(type);
  };

  const renderMessage = () => (
    <Notification
      classNames={styles.message}
      open={!!message}
      onClose={handleMessageClear}
      text={message}
      type={messageType}
    />
  );

  const handleUpdate = (
    params: Omit<UpdateCreativeTaskParams, "id" | "role" | "isSubmit">
  ) => {
    if (!id) return;

    updateCreativeTask(
      {
        ...params,
        id,
        isSubmit: false,
        role: isTeacher ? "teacher" : "student",
        initialErrors: data?.exerciseResult.errors,
        initialCorrections: data?.exerciseResult.corrections,
      },
      {
        onSuccess: () => {
          const message = isTeacher
            ? t("CreativeTasks:UpdateSavedTeacher")
            : t("CreativeTasks:UpdateSavedStudent");
          showMessageWithType(message, "info");
        },
      }
    );
  };

  const handleSubmit = async (
    params: Omit<UpdateCreativeTaskParams, "id" | "role" | "isSubmit">
  ) => {
    if (!id) return;

    updateCreativeTask(
      {
        ...params,
        id,
        isSubmit: true,
        role: isTeacher ? "teacher" : "student",
        initialErrors: data?.exerciseResult.errors,
        initialCorrections: data?.exerciseResult.corrections,
      },
      {
        onSuccess: () => {
          const message = isTeacher
            ? t("CreativeTasks:UpdateSubmitedTeacher")
            : t("CreativeTasks:UpdateSubmitedStudent");
          showMessageWithType(message, "success");
        },
      }
    );
  };

  useEffect(() => {
    handleMessageClear();
  }, [id]);

  return (
    <Modal
      className={styles["container"]}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Loading loading={isLoading}>
        <div className={styles["inner"]}>
          <div className={styles["header"]}>
            <Text
              containerClassName={styles["title"]}
              variant="h2-mob"
              color="base-d"
            >
              {t("CreativeTasks:ResultTitle")}
            </Text>
            <Icon
              containerClassName={styles.close}
              icon="close-modal"
              onClick={onClose}
            />
          </div>

          <div className={styles["cards"]}>
            <div className={styles["task"]}>
              <div className={styles["student"]}>
                <Text variant="text-1-semibold" color="base-d">
                  {data?.exerciseResult.userName}
                </Text>
                <Text variant="text-3-regular" color="base-dl">
                  {t(
                    isTeacher
                      ? "CreativeTasks:Student"
                      : "CreativeTasks:Teacher"
                  )}
                </Text>
              </div>
              <Text variant="text-2-link" color="base-d">
                {data?.exerciseResult.courseName}
              </Text>
              <div className={styles["unit"]}>
                <Text variant="text-2-regular" color="accsent-1s">
                  {t("CreativeTasks:TaskSubtitle", {
                    unit: data?.exerciseResult.unit,
                    part: data?.exerciseResult.part,
                    exercise: data?.exerciseResult.exercise,
                  })}
                </Text>
                <Text variant="text-2-regular" color="base-dl">
                  {t("CreativeTasks:CurrentVersionTime", {
                    time: formatTime(new Date()),
                  })}
                </Text>
              </div>

              <Text variant="text-2-regular" color="base-d">
                {t("CreativeTasks:TaskText")}
              </Text>
              <Text variant="text-2-regular" color="base-d">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.exercise.content.taskText ?? "",
                  }}
                />
              </Text>

              <CheckingHistory data={data?.exerciseResultCheckHistory ?? []} />
            </div>

            <AnswerWrapper
              type={data?.exerciseResult.exerciseType}
              comment={data?.exerciseResult.comment ?? ""}
              errors={data?.exerciseResult.errors ?? []}
              passing={data?.exerciseResult.passing}
              corrections={data?.exerciseResult.corrections ?? []}
              isTeacher={isTeacher ?? false}
              hasErrors={data?.exerciseResult.hasErrors ?? false}
              detailsLoading={isLoading}
              onSubmit={handleSubmit}
              onUpdate={handleUpdate}
              renderMessage={renderMessage}
              updateLoading={isPending}
              submitLoading={isPending}
            />
          </div>
        </div>
      </Loading>
    </Modal>
  );
});
