import { convertExerciseTimeToDate } from "./index";

type Role = "teacher" | "student";

export type GetCreativeTasksParams = {
  role: Role;
};

export type GetCreativeTaskParams = {
  id: string;
  role: Role;
};

export type UpdateCreativeTaskParams = {
  id: string;
  comment: string;
  role: Role;
  isSubmit: boolean;
  initialErrors?: ExerciseResultError[];
  errors?: ExerciseResultError[];
  initialCorrections?: ExerciseResultCorrection[];
  corrections?: ExerciseResultCorrection[];
};

export type ExerciseResultStatus = "new_check" | "processing" | "checked";
export type ExerciseResultExerciseType = "speaking" | "writing";

type CreativeTasksResponseExerciseResults = {
  id: string;
  exerciseType: ExerciseResultExerciseType;
  courseName: string;
  createdAt: string;
  createdAtTimestamp: string;
  checkedAt: string | null;
  checkedAtTimestamp: string | null;
  userName: string;
  teacher: string;
  status: ExerciseResultStatus;
  hasErrors: boolean;
  breadcrumbs: string[];
  exerciseName?: string;
  exerciseOrder?: number;
}[];

export type CreativeTasksResponse = {
  results?: CreativeTasksResponseExerciseResults;
  exerciseResults?: CreativeTasksResponseExerciseResults;
};

export const transformCreativeTasksResponse = (
  response: CreativeTasksResponse
) => {
  return (response.results ?? response.exerciseResults ?? [])
    .map((result) => ({
      id: result.id,
      exerciseType: result.exerciseType,
      name: result.courseName,
      userName: result.userName ?? result.teacher,
      status: result.status,
      createdAt: convertExerciseTimeToDate(
        result.createdAt ?? result.createdAtTimestamp
      ) as Date,
      checkedAt: convertExerciseTimeToDate(
        result.checkedAt ?? result.checkedAtTimestamp
      ),
      hasErrors: result.hasErrors,
      unit: (result.breadcrumbs[0] ?? "").replace(/(Unit|Юнит)/i, ""),
      part: result.breadcrumbs[1] ?? "",
      exercise: `${result.exerciseOrder} ${result.exerciseName}`,
    }))
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
};

export type ExerciseResult = ReturnType<
  typeof transformCreativeTasksResponse
>[number];

export enum ExerciseErrorType {
  COMMENT = "0",
  GRAMMAR = "1",
  LEXICAL = "2",
}

export type CreativeTaskResponse = {
  exerciseResult: {
    id: string;
    exerciseType: ExerciseResultExerciseType;
    userName: string;
    courseName: string;
    breadcrumbs: string[];
    exerciseName: string;
    exerciseOrder: number;
    comment: string;
    errors: {
      id: string;
      startPosition: number;
      endPosition: number;
      comment: string;
      exercisesErrorTypeId: ExerciseErrorType;
    }[];
    comments: {
      id: string;
      startPosition: number;
      endPosition: number;
      comment: string;
    }[];
    corrections?: {
      id: string;
      startPosition: number;
      endPosition: number;
      text: string;
    }[];
    data: {
      questionPassings: {
        userAnswer: {
          answer?: string;
          recordUrl?: string;
        };
      }[];
    };
  };
  exercise: {
    content: {
      taskText: string;
    };
  };
  exerciseResultCheckHistory: {
    id: string;
    checkDate: string;
    originDate: string;
    hasErrors: boolean;
    teacherName: string;
  }[];
};

export const transformCreativeTaskResponse = (
  response: CreativeTaskResponse
) => {
  const getTransformedPassing = () => {
    // Используем только первый пассинг
    const passing = response.exerciseResult.data.questionPassings[0];
    return {
      answer: (passing.userAnswer.answer ??
        passing.userAnswer.recordUrl) as string,
    };
  };

  return {
    ...response,
    exerciseResult: {
      ...response.exerciseResult,
      unit: (response.exerciseResult.breadcrumbs[0] ?? "").replace(
        /(Unit|Юнит)/i,
        ""
      ),
      part: response.exerciseResult.breadcrumbs[1] ?? "",
      exercise: `${response.exerciseResult.exerciseOrder} ${response.exerciseResult.exerciseName}`,
      hasErrors: !!response.exerciseResult.errors.length,
      errors: [
        ...response.exerciseResult.errors.map((error) => ({
          currentId: error.id,
          startPosition: error.startPosition,
          endPosition: error.endPosition,
          comment: error.comment,
          errorTypeId: error.exercisesErrorTypeId,
        })),
        ...response.exerciseResult.comments.map((comment) => ({
          currentId: comment.id,
          startPosition: comment.startPosition,
          endPosition: comment.endPosition,
          comment: comment.comment,
          errorTypeId: "0" as ExerciseErrorType,
        })),
      ],
      corrections: response.exerciseResult.corrections?.map((correction) => ({
        currentId: correction.id,
        startPosition: correction.startPosition,
        endPosition: correction.endPosition,
        text: correction.text,
      })),
      passing: getTransformedPassing(),
    },
    exerciseResultCheckHistory: response.exerciseResultCheckHistory.map(
      (history) => ({
        ...history,
        checkDate: new Date(history.checkDate),
        originDate: new Date(history.originDate),
      })
    ),
  };
};

type TransformedCreativeTaskResponse = ReturnType<
  typeof transformCreativeTaskResponse
>;

export type ExerciseResultError =
  TransformedCreativeTaskResponse["exerciseResult"]["errors"][number];

export type ExerciseResultCorrection = Exclude<
  TransformedCreativeTaskResponse["exerciseResult"]["corrections"],
  undefined
>[number];

export type ExerciseResultPassing =
  TransformedCreativeTaskResponse["exerciseResult"]["passing"];
