import { useLayoutEffect, useRef, useState } from "react";

export function useOffset() {
  const containerRef = useRef<HTMLDivElement>(null);

  const [leftOffset, setLeftOffset] = useState(0);
  const [topOffset, setTopOffset] = useState(0);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const rect = container.getBoundingClientRect();

    const leftOffset = window.innerWidth - rect.right;
    const topOffset = rect.top;

    if (leftOffset < 0) {
      setLeftOffset(leftOffset);
    } else if (leftOffset > 0) {
      setLeftOffset(0);
    }

    if (topOffset < 0) {
      setTopOffset(topOffset);
    } else if (topOffset > 0) {
      setTopOffset(0);
    }
  }, [containerRef.current, setLeftOffset, setTopOffset]);

  return { containerRef, leftOffset, topOffset };
}
