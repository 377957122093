import { useEffect } from "react";

import { Box, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";

import { Button } from "components/Button";
import { IconContainer } from "components/CardClass/styledComponents";
import { Icon } from "components/Icon";
import { ItemsWithPagination } from "components/ItemsWithPagination";
import { JetLink } from "components/JetLink";
import { Loading } from "components/Loading";

import {
  Card,
  ModuleName,
  CardsContainer,
  Title,
  CompanyName,
  Info,
  HRWidgetStyled,
  HRWidgetsWrapper,
} from "./styled";
import { Wrapper } from "../styled";

const HRMainPage = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { course, hrStatistics, auth, learningGroup, api } = useStores();
  const { studentsCount, licensesCount, getStatistics } = hrStatistics;
  const { user } = auth;
  const { activeGroups } = learningGroup;
  const activeGroupCount = activeGroups.length;
  const navigate = useNavigate();

  const {
    fetch: fetchGroups,
    loading: loadingGroups,
    error: groupsError,
  } = useCancelableFetch();
  const {
    fetch: fetchCourses,
    loading: loadingCourses,
    error: coursesError,
  } = useCancelableFetch();

  const isLoading = loadingGroups || loadingCourses;
  const isError = Boolean(groupsError || coursesError);

  const onGroupsClick = (id: string) => () => {
    navigate(`/groups?module=${id}`);
  };

  useEffect(() => {
    getStatistics();
  }, [getStatistics]);

  useEffect(() => {
    const filters = { includeLessons: true };
    fetchGroups((token) => api.getLearningGroups(filters, token));
  }, [api, fetchGroups]);

  useEffect(() => {
    fetchCourses((token) => api.getCourses(token));
  }, [api, fetchCourses]);

  const items = course.items.map((item) => (
    <Card key={item.id}>
      <Box display="flex" alignItems="center">
        <IconContainer
          iconColor={theme.palette.info.light}
          variant="default"
          theme={theme}
        >
          <Icon type="bookReader" width={22} height={22} />
        </IconContainer>
        <ModuleName>
          {" "}
          <JetLink to={`/my-courses/${item.id}`} underline="always">
            {item.name}
          </JetLink>
        </ModuleName>
      </Box>
      <Button
        color="secondary"
        variant="secondary"
        onClick={onGroupsClick(item.id)}
      >
        {t("Global:Groups")}
      </Button>
    </Card>
  ));

  return (
    <Wrapper flexDirection="column">
      <CompanyName>{user?.companyName}</CompanyName>
      <Info>{t("Global:Information")}</Info>

      <Loading loading={isLoading} error={isError}>
        <HRWidgetsWrapper>
          <HRWidgetStyled
            title={t("HRWidget:TotalActiveGroups")}
            number={`${activeGroupCount}`}
            background="red"
            iconType="HRWidgetGroup"
          />
          <HRWidgetStyled
            title={t("HRWidget:TotalActiveStudents")}
            number={`${studentsCount}`}
            background="green"
            iconType="HRWidgetStudent"
          />
          <HRWidgetStyled
            title={t("HRWidget:TotalActiveLicenses")}
            number={`${licensesCount}`}
            background="violet"
            iconType="HRWidgetLicense"
          />
        </HRWidgetsWrapper>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            marginTop="30px"
            marginBottom="30px"
          >
            <Title>{t("Global:Modules")}</Title>
          </Box>
          <ItemsWithPagination
            items={items}
            countPage={6}
            ContainerItems={CardsContainer}
          />
        </Box>
      </Loading>
    </Wrapper>
  );
};

export const HRMain = observer(HRMainPage);
