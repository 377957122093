import {
  ReportResponse,
  ReportsResponse,
  transformReportResponse,
  DistributorReportType,
  transformReportsResponse,
} from "./types";
import { api } from "../api";

export interface DeleteReportsParams {
  ids: string[];
  type: DistributorReportType;
}

export interface CreateReportParams {
  title: string;
  type: string;
  groups?: string[];
  from?: string;
  to?: string;
}

export interface SendReportsToMailParams {
  ids: string[];
}

export const getReports = (type: DistributorReportType) => {
  return api
    .get<ReportsResponse>(`/api/v3/reports?filter[type]=${type}`)
    .then((res) => transformReportsResponse(res.data, type));
};

export const getReport = (reportId: string) => {
  return api
    .get<ReportResponse>(`/api/v3/reports/${reportId}`)
    .then((res) => transformReportResponse(res.data));
};

export const deleteReports = (params: DeleteReportsParams) => {
  return api.delete(`/api/v3/reports`, { params });
};

export const createReport = (params: CreateReportParams) => {
  const { title: name, type, from: date_from, to: date_to, groups } = params;

  const body: Record<string, any> = { name, type };

  if (date_from) body.date_from = date_from;
  if (date_to) body.date_to = date_to;
  if (groups) body.parameters.groups = groups;

  return api
    .post<ReportsResponse>("/api/v3/reports/", body)
    .then((res) => res.data);
};

export const sendReportsToMail = (params: SendReportsToMailParams) => {
  const { ids } = params;

  return api.post("/api/v3/reports/to_email", { ids });
};
