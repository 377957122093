const pt = {
  CreativeTasks: {
    Title: "Tarefas Criativas",
    UpdateTaskTeacher: "Salvar",
    SubmitTaskTeacher: "Enviar resultados",
    UpdateTaskStudent: "Salvar rascunho",
    SubmitTaskStudent: "Salvar e enviar para verificação",
    AnswerHeaderStatistics:
      " Questões: {{questions}} | Caracteres: {{symbols}} | Palavras: {{words}} | Frases: {{sentenses}}",
    QuestionsAndComments: "Erros e comentários:",
    HoverForDisplay: "Passe o mouse para visualizar:",
    CheckingHistory: "Histórico de verificações",
    CurrentVersion: "Versão atual",
    TeacherComment: "Comentário do professor:",
    CommentPlaceholder: "Deixe seu comentário aqui",
    SaveCorrection: "Salvar",
    CancelCorrection: "Cancelar",
    Grammar: "Gramática",
    Lexical: "Léxico",
    Comment: "Comentário",
    ErrorRangeFilled: "Você usou todos os tipos de erro na seleção",
    SaveError: "Salvar",
    GrammarErrorTitle: "Gramática",
    ResultTitle: "Resultado da tarefa criativa",
    Student: "Estudante",
    Teacher: "Professor",
    CurrentVersionTime: "Versão atual: {{time}}",
    TaskText: "Tarefa",
    HasErrors: "Contém erros",
    WithoutErrors: "Concluído sem erros",
    UpdateSavedTeacher: "Alterações salvas",
    UpdateSavedStudent: "Rascunho salvo",
    UpdateSubmitedTeacher: "Resultados enviados",
    UpdateSubmitedStudent: "Alterações enviadas",
    Checking: "Em verificação",
    Done: "Concluído",
    FixStudent: "Corrigir",
    FixTeacher: "Contém erros",
    CheckingTime: "verificação • {{time}}",
    DoneTime: "enviado • {{time}}",
    TeacherName: "Professor: {{name}}",
    StudentName: "Estudante: {{name}}",
    AllStatuses: "Todos status",
    Checked: "Verificado",
    NewCheck: "Em verificação",
    Processing: "Processando",
    StudentSearch: "Buscar estudante",
    TeacherSearch: "Buscar professor",
    EmptyList: "Nenhuma tarefa encontrada",
    TaskSubtitle: "Módulo {{unit}} • {{part}} • Exercício {{exercise}}",
  },
};

export default pt;
