import { useState } from "react";

import { useTranslation } from "react-i18next";

import styles from "./CheckingHistory.module.scss";
import { formatTime } from "../../../utils/helpers";
import { Icon } from "../../Icon";
import { Text } from "../../Text";

interface CheckingHistoryProps {
  data: {
    id: string;
    teacherName: string;
    originDate: Date;
    checkDate: Date;
    hasErrors: boolean;
  }[];
}

export const CheckingHistory = (props: CheckingHistoryProps) => {
  const { data } = props;

  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((p) => !p);
  };

  return (
    <div className={styles["checking-history"]}>
      <div className={styles["accordion"]} onClick={toggleShow}>
        <Icon icon="accordion" />
        <Text variant="text-2-regular" color="accsent-1s">
          {t("CreativeTasks:CheckingHistory")}
        </Text>
      </div>
      {show && (
        <>
          {data.map((history) => (
            <div key={history.id} className={styles["checking-history-item"]}>
              <Text color="base-dl" variant="text-2-regular">
                {formatTime(history.originDate)}
              </Text>
              <Text
                color={history.hasErrors ? "accsent-3s" : "accsent-2s"}
                variant="text-2-regular"
                containerClassName={styles["checking-history-status"]}
              >
                {history.hasErrors
                  ? t("CreativeTasks:HasErrors")
                  : t("CreativeTasks:WithoutErrors")}
              </Text>
              <Text color="base-dl" variant="text-2-regular">
                {formatTime(history.checkDate)}
              </Text>
              <Text variant="text-2-regular" color="accsent-1s">
                {history.teacherName}
              </Text>
            </div>
          ))}
          <div className={styles["checking-history-item"]}>
            <Text color="base-dl" variant="text-2-regular">
              {formatTime(new Date())}
            </Text>
            <Text variant="text-2-regular">
              {t("CreativeTasks:CurrentVersion")}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};
