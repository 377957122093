import { observer } from "mobx-react-lite";

import { LoginForm, Intro, LinkDrawer } from "./components";
import { Container } from "./styledComponents";

export const Login = observer((): JSX.Element => {
  return (
    <>
      <Container>
        <Intro />
        <LoginForm />
        <LinkDrawer />
      </Container>
    </>
  );
});
