// import { FOOTER_LINKS } from "components/Footer/constants";
// import { Icon } from "components/Icon";
// import { ROUTES } from "router/constants";
// import { theme } from "themes/default";
// import { ColorVariantType, MenuItem } from "types";

export const sentryConfig = {
  dsn: "https://c50d3850c154429789ecf623870024d2@o4505110586327040.ingest.sentry.io/4505110591307776",
  tunnel: "/api/sentry_tunnel/",
  environment: process.env.NODE_ENV ?? "production",
};

export const CURRENT_STUDENT_COURSE_ID_KEY = "currentStudentCourseId";

// export const text = `
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//   eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//   ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//   aliquip ex ea commodo consequat.
// `;

// export const promoVideoLink =
//   "https://storage.yandexcloud.net/jetspeak-prod/video_tracks/upload/JetClass_Introduction.mp4";

// export const emptyFilterItem = "none";

// export const routerLinks = FOOTER_LINKS;

// export const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// export const dataTeachers = [
//   {
//     name: "Anna Nikolaevna Sidorova",
//     post: "Senior Teacher",
//   },
//   {
//     name: "Alexander McCalister",
//     post: "Teacher",
//   },
//   {
//     name: "Martin McFly",
//     post: "Teacher",
//   },
//   {
//     name: "Alexander McCalister",
//     post: "Teacher",
//   },
//   {
//     name: "Anna Nikolaevna Sidorova",
//     post: "Senior Teacher",
//   },
// ];

// export const levels = [
//   { label: "A1", value: "A1" },
//   { label: "A2", value: "A2" },
//   { label: "B1", value: "B1" },
//   { label: "B2", value: "B2" },
//   { label: "C1", value: "C1" },
//   { label: "C2", value: "C2" },
//   { label: "All levels", value: "" },
// ];

// export const allDataMenuItems: Record<string, MenuItem> = {
//   dashboard: {
//     icon: <Icon type="dashboard" width={32} height={32} />,
//     label: "Dashboard",
//     path: "/",
//   },
//   myWords: {
//     icon: <Icon type="words" width={32} height={32} />,
//     label: "My words",
//     path: "/my-words",
//   },
//   groups: {
//     icon: <Icon type="group" width={32} height={32} />,
//     label: "Groups",
//     path: "/groups",
//   },
//   classes: {
//     icon: <Icon type="classes" width={32} height={32} />,
//     label: "Classes",
//     path: ROUTES.CLASSES,
//   },
//   attendance: {
//     icon: <Icon type="attendance" width={32} height={32} />,
//     label: "Attendance",
//     path: "/attendance-report",
//   },
//   reports: {
//     icon: <Icon type="reports" width={32} height={32} />,
//     label: "Reports",
//     path: ROUTES.STUDENTS_REPORTS,
//   },
//   modules: {
//     icon: <Icon type="course" width={32} height={32} />,
//     label: "Modules",
//     path: ROUTES.MY_COURSES,
//   },
//   myModules: {
//     icon: <Icon type="course" width={32} height={32} />,
//     label: "My modules",
//     path: ROUTES.MY_COURSES,
//   },
//   additionalModules: {
//     icon: <Icon type="bag" width={32} height={32} />,
//     label: "Additional modules",
//     path: "/additional-modules",
//   },
//   teachers: {
//     icon: <Icon type="teachers" width={32} height={32} />,
//     label: "Teachers",
//     path: "/teachers",
//   },
//   teachersCorner: {
//     icon: <Icon type="file" width={32} height={32} />,
//     label: "Teacher’s corner",
//     path: "/teacher-corner",
//   },
//   studentsCorner: {
//     icon: <Icon type="file" width={32} height={32} />,
//     label: "Student's corner",
//     path: "/students-corner",
//   },
//   usefulLinks: {
//     icon: <Icon type="link" width={32} height={32} />,
//     label: "Useful links",
//     path: "/useful-links",
//   },
//   salaries: {
//     icon: <Icon type="salaries" width={32} height={32} />,
//     label: "Salaries",
//     path: "/salaries",
//   },
//   odds: {
//     icon: <Icon type="odds" width={32} height={32} />,
//     label: "Odds",
//     path: "/odds",
//   },
// };

// export const dataMenuHR = [
//   allDataMenuItems.dashboard,
//   allDataMenuItems.groups,
//   allDataMenuItems.attendance,
//   allDataMenuItems.reports,
//   allDataMenuItems.modules,
//   allDataMenuItems.additionalModules,
//   allDataMenuItems.usefulLinks,
// ];

// export const dataMenuDistributor = [
//   allDataMenuItems.dashboard,
//   allDataMenuItems.groups,
//   allDataMenuItems.reports,
//   allDataMenuItems.attendance,
//   allDataMenuItems.classes,
//   allDataMenuItems.myModules,
// ];

// export const dataMenuStudents = [
//   allDataMenuItems.dashboard,
//   allDataMenuItems.myWords,
//   allDataMenuItems.myModules,
//   allDataMenuItems.teachersCorner,
//   allDataMenuItems.additionalModules,
//   allDataMenuItems.usefulLinks,
// ];

// export const dataMenuTeachers = [
//   allDataMenuItems.dashboard,
//   allDataMenuItems.groups,
//   allDataMenuItems.attendance,
//   allDataMenuItems.classes,
//   allDataMenuItems.reports,
//   allDataMenuItems.myModules,
//   allDataMenuItems.studentsCorner,
//   allDataMenuItems.additionalModules,
//   allDataMenuItems.usefulLinks,
// ];

// export const dataMenuPayrollAccountant = [
//   allDataMenuItems.dashboard,
//   allDataMenuItems.teachers,
//   allDataMenuItems.groups,
//   allDataMenuItems.classes,
//   allDataMenuItems.attendance,
//   allDataMenuItems.salaries,
//   allDataMenuItems.odds,
// ];

// export const currentModule = { title: "JetClass Business General ", url: "!#" };

// export const levelEnglish = "Intermediate";

// export const dueDate = "2021-07-05T14:27:22Z";

// export const variant = "success";

// export const iconType = "elementary";

// export const lesson = {
//   titleLesson: "Lesson 1",
//   titleUnit: "Unit 12: Personality",
// };

// export const dataModuleCard = [
//   {
//     id: 1,
//     dueDate: "2021-07-05T14:27:22Z",
//     module: { title: "JetClass Business General ", url: "!#" },
//     levelEnglish: "Intermediate",
//   },
//   {
//     id: 2,
//     dueDate: "2021-07-05T14:27:22Z",
//     module: { title: "JetClass Business General", url: "!#" },
//     levelEnglish: "Intermediate",
//   },
//   {
//     id: 3,
//     dueDate: "2021-07-05T14:27:22Z",
//     module: { title: "JetClass Business General", url: "!#" },
//     levelEnglish: "Elementary",
//   },
//   {
//     id: 4,
//     dueDate: "2021-07-05T14:27:22Z",
//     module: { title: "JetClass Business General", url: "!#" },
//     levelEnglish: "Intermediate",
//   },
//   {
//     id: 3,
//     dueDate: "2021-07-05T14:27:22Z",
//     module: { title: "JetClass Business General", url: "!#" },
//     levelEnglish: "Elementary",
//   },
//   {
//     id: 4,
//     dueDate: "2021-07-05T14:27:22Z",
//     module: { title: "JetClass Business General", url: "!#" },
//     levelEnglish: "Intermediate",
//   },
// ];

export const emptyModule = {
  available: false,
  code: "",
  id: "",
  isForTesting: false,
  markPercent: 0,
  passingPercent: 0,
  picture: null,
  updatedAt: "",
  courseUnits: [],
  knowledgeLevel: { code: "" },
  isHide: true,
};

// export const myModules = [
//   {
//     module: currentModule,
//     levelEnglish,
//     dueDate,
//     dataTeachers,
//     key: 0,
//   },
//   {
//     module: currentModule,
//     levelEnglish,
//     dueDate,
//     dataTeachers,
//     key: 1,
//   },
//   {
//     module: currentModule,
//     levelEnglish,
//     dueDate,
//     dataTeachers,
//     key: 2,
//   },
// ];

// export const colorVariant: ColorVariantType = {
//   default: theme.palette.custom.grey.main,
//   passed: theme.palette.success.main,
//   wrong: theme.palette.warning.main,
//   current: theme.palette.info.main,
// };

// export const PROMOCODE_KEY = "jetclass_promocode";

// export const mockTeachers = [
//   {
//     id: "0",
//     photo:
//       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAUQbM0coQ3Oygepmz8mdI9fvcxVKiGku3h3QW0IUmhQ&s",
//     name: "Константин",
//     lastName: "Константинопольский",
//     middleName: "Константинович",
//     fullName: "Константинопольский Константин Константинович",
//     email: "kkonstantinopolski90@yandex.ru",
//     phone: "+7-968-110-39-32",
//     langs: [
//       {
//         id: "0",
//         name: "Английский",
//         shortName: "ENG",
//         nativeSpeaker: true,
//         types: [
//           {
//             id: "0",
//             name: "Webinar",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//           {
//             id: "1",
//             name: "Face2Face",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//         ],
//       },
//     ],
//     formOfEmployment: "Прогресс",
//     entity: "Штат",
//     placeOfIssue: "Москва",
//     issueType: "ЗП карта + наличные",
//     issuingQueue: "Зарплата",
//   },
//   {
//     id: "1",
//     photo: null,
//     name: "Константин",
//     lastName: "Константинопольский",
//     middleName: "Константинович",
//     fullName: "Константинопольский Константин Константинович",
//     email: "kkonstantinopolski90@yandex.ru",
//     phone: "+7-968-110-39-32",
//     langs: [
//       {
//         id: "0",
//         name: "Английский",
//         shortName: "ENG",
//         nativeSpeaker: true,
//         types: [
//           {
//             id: "0",
//             name: "Webinar",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//           {
//             id: "1",
//             name: "Face2Face",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//         ],
//       },
//       {
//         id: "1",
//         name: "Французский",
//         shortName: "FR",
//         nativeSpeaker: true,
//         types: [
//           {
//             id: "0",
//             name: "Webinar",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//           {
//             id: "1",
//             name: "Face2Face",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//         ],
//       },
//     ],
//     formOfEmployment: "Прогресс",
//     entity: "Штат",
//     placeOfIssue: "Москва",
//     issueType: "ЗП карта + наличные",
//     issuingQueue: "Зарплата",
//   },
//   {
//     id: "2",
//     photo: null,
//     name: "Константин",
//     lastName: "Константинопольский",
//     middleName: "Константинович",
//     fullName: "Константинопольский Константин Константинович",
//     email: "kkonstantinopolski90@yandex.ru",
//     phone: "+7-968-110-39-32",
//     langs: [
//       {
//         id: "0",
//         name: "Английский",
//         shortName: "ENG",
//         nativeSpeaker: true,
//         types: [
//           {
//             id: "0",
//             name: "Webinar",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//           {
//             id: "1",
//             name: "Face2Face",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//         ],
//       },
//       {
//         id: "1",
//         name: "Французский",
//         shortName: "FR",
//         nativeSpeaker: true,
//         types: [
//           {
//             id: "0",
//             name: "Webinar",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//           {
//             id: "1",
//             name: "Face2Face",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//         ],
//       },
//       {
//         id: "2",
//         name: "Английский",
//         shortName: "ENG",
//         nativeSpeaker: true,
//         types: [
//           {
//             id: "0",
//             name: "Webinar",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//           {
//             id: "1",
//             name: "Face2Face",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//         ],
//       },
//       {
//         id: "3",
//         name: "Английский",
//         shortName: "ENG",
//         nativeSpeaker: true,
//         types: [
//           {
//             id: "0",
//             name: "Webinar",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//           {
//             id: "1",
//             name: "Face2Face",
//             rate: "375",
//             appointmentDate: "22.12.2020",
//           },
//         ],
//       },
//     ],
//     formOfEmployment: "Прогресс",
//     entity: "Штат",
//     placeOfIssue: "Москва",
//     issueType: "ЗП карта + наличные",
//     issuingQueue: "Зарплата",
//   },
// ];

// export type Teacher = (typeof mockTeachers)[number];

// export const mockReferenceBooks = [
//   {
//     id: 0,
//     title: "Пользователи",
//     items: [
//       { id: 0, title: "Студенты", count: 12 },
//       { id: 1, title: "Преподаватели", count: 122 },
//       { id: 2, title: "Сотрудники", count: 1232 },
//       // { id: 3, title: "lba", count: 2 },
//     ],
//   },
//   {
//     id: 1,
//     title: "Обучение",
//     items: [
//       { id: 0, title: "Группы", count: 12 },
//       { id: 1, title: "Прогресс студентов", count: 122 },
//       { id: 2, title: "Результаты тестов", count: 1232 },
//       // { id: 3, title: "lba", count: 2 },
//     ],
//   },
//   {
//     id: 2,
//     title: "Контент",
//     items: [
//       { id: 0, title: "Курсы", count: 12 },
//       { id: 1, title: "Лексика", count: 122 },
//       { id: 2, title: "Речевые тренажеры", count: 1232 },
//       // { id: 3, title: "lba", count: 2 },
//     ],
//   },
//   {
//     id: 3,
//     title: "Лицензия и отчеты",
//     items: [
//       { id: 0, title: "Лицензия", count: 12 },
//       { id: 1, title: "Отчет по посещаемости занятий", count: 122 },
//       { id: 2, title: "Отчет по прогрессу студентов", count: 1232 },
//       // { id: 3, title: "lba", count: 2 },
//     ],
//   },
// ];

// export const mockPayrollGroups = [
//   {
//     id: "0",
//     name: "PMI_13_MoscowHQ_CP_Elementary",
//     teachers: ["Канакова Е.Ю "],
//     lang: "ENG",
//     nativeSpeaker: false,
//     rate: "1500",
//   },
//   {
//     id: "1",
//     name: "PMI_13_MoscowHQ_CP_Elementary",
//     teachers: ["Канакова Е.Ю "],
//     lang: "ENG",
//     nativeSpeaker: true,
//     rate: "1500",
//   },
//   {
//     id: "2",
//     name: "PMI_13_MoscowHQ_CP_Elementary",
//     teachers: ["Канакова Е.Ю "],
//     lang: "ENG",
//     nativeSpeaker: true,
//     rate: "1500",
//   },
// ];
