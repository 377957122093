/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";

import { ProfileCountryISO, ProfileCountryModel } from "./ProfileCountry";
import { UserRole } from "../../types";
import { LicenseModel } from "../License";
import { RootStore } from "../RootStore/RootStore";

export const UserModel = types
  .model("User")
  .props({
    id: types.identifier,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    locale: types.maybeNull(types.string),
    roles: types.array(types.string),
    companyName: types.maybeNull(types.string),
    licenses: types.maybeNull(types.array(LicenseModel)),
    isEmailConfirmed: types.maybeNull(types.boolean),
    profileCountry: types.maybeNull(ProfileCountryModel),
    invalidProfiles: types.optional(types.array(types.string), []),
  })
  .views((self) => ({
    get fullName() {
      return `${self.firstName} ${self.lastName}`;
    },
    get isRussian() {
      return self?.profileCountry?.iso?.toLowerCase() === ProfileCountryISO.RU;
    },
  }))
  .views((self) => ({
    get isStudent(): boolean {
      return self.roles.includes(UserRole.STUDENT);
    },
    get isTeacher(): boolean {
      return self.roles.includes(UserRole.TEACHER);
    },
    get isHR(): boolean {
      return self.roles.includes(UserRole.HR);
    },
    get isDistributor(): boolean {
      return self.roles.includes(UserRole.DISTRIBUTOR);
    },
    get isCoordinator(): boolean {
      return self.roles.includes(UserRole.COORDINATOR);
    },
    get isPayrollAccountant(): boolean {
      return self.roles.includes(UserRole.PAYROLL_ACCOUNTANT);
    },
  }))
  .views((self) => ({
    get role(): UserRole {
      if (self.isHR) {
        return UserRole.HR;
      }
      if (self.isCoordinator) {
        return UserRole.COORDINATOR;
      }
      if (self.isDistributor) {
        return UserRole.DISTRIBUTOR;
      }
      if (self.isPayrollAccountant) {
        return UserRole.PAYROLL_ACCOUNTANT;
      }
      if (self.isTeacher) {
        return UserRole.TEACHER;
      }
      if (self.isStudent) {
        return UserRole.STUDENT;
      }
      return UserRole.USER;
    },
  }))
  .views((self) => ({
    get needMoreInformationForAnatele() {
      return (
        self.role === UserRole.USER && self.invalidProfiles.includes("anatele")
      );
    },
  }))
  .actions((self) => ({
    async updatePassword(newPassword: string) {
      const rootStore = getRoot<RootStore>(self);

      await rootStore.updateUserPassword(newPassword);
    },
    setIsEmailConfirmed(value: boolean) {
      self.isEmailConfirmed = value;
    },
  }));

type UserType = Instance<typeof UserModel>;
export type User = UserType;
type UserSnapshotType = SnapshotOut<typeof UserModel>;
export type UserSnapshot = UserSnapshotType;
export const createUserDefaultModel = () =>
  types.optional(UserModel, {} as UserType);
