import {
  ExerciseResultError,
  ExerciseErrorType,
} from "@packages/data/creativeTasks";

import { isInRange } from "./isInRange";
import { wordInRanges } from "./wordInRanges";
import { TextColor } from "../../../Text";

export function getWordColor(
  wordIndex: number,
  errors: ExerciseResultError[]
): TextColor | undefined {
  if (!wordInRanges(errors, wordIndex)) {
    return undefined;
  }

  const error = errors.find((error) => isInRange(error, wordIndex));
  return error?.errorTypeId === ExerciseErrorType.COMMENT
    ? "accsent-1s"
    : "accsent-3s";
}
