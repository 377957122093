export enum DistributorReportType {
  Students = "Reports::StudentsReport",
  Teachers = "Reports::TeachersReport",
  Lesson = "Reports::Lessons",
  KnowledgeLevels = "Reports::KnowledgeLevelsReport",
}

interface ReportContentData {
  processingPercent: number;
  processingTestPercent: number;
  progressPercent: number;
  progressTestPercent: number;
}

type LearningCourses = { [id: string]: { id: string; name: string } };

interface Record {
  courseId: string;
  studentId: string;
  studentName: string;
  course: ReportContentData;
  unitPassings: {
    [id: string]: ReportContentData;
  };
  blockPassings: {
    [id: string]: ReportContentData;
  };
  exercisePassings: {
    [id: string]: { processingPercent: number };
  };
}

interface ReportData {
  groupId: string;
  groupName: string;
  learningCourses: LearningCourses;
  records: Record[];
}

export interface ReportResponse {
  id: string;
  name: string;
  dateFrom: string;
  dateTo: string;
  status: string;
  createdAt: string;
  sendToEmailPath: string;
  downloadUrl: string;
  xlsxPath: string;
  type: DistributorReportType;

  data: ReportData[];
}

export type ReportsResponse = Omit<ReportResponse, "data">[];

export const transformReportsResponse = (
  response: ReportsResponse,
  type: DistributorReportType
) => {
  return response.map((res) => ({
    ...res,
    type,
  }));
};

export const transformReportResponse = (response: ReportResponse) => {
  return {
    ...response,
    data: response.data.map((item) => ({
      ...item,
      learningCourses: Object.keys(item.learningCourses),
      records: item.records.map((record) => ({
        ...record,
        studentName: record.studentName.split("(")[0].trim(),
        studentMail: record.studentName.split("(")[1].trim().slice(0, -1),
      })),
    })),
  };
};

export type Report = ReturnType<typeof transformReportResponse>;
