/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { TypeModel } from "./Type";
import { AdditionalContentModel } from "../AdditionalContent/AdditionalContent";
import { Exercise, ExerciseModel } from "../Exercise/Exercise";

export const BlockModel = types
  .model("BlockModel")
  .props({
    id: types.identifier,
    available: types.maybeNull(types.boolean),
    childrenListUpdatedAt: types.maybeNull(types.Date),
    completedExercisesCount: types.maybeNull(types.number),
    mark: types.maybeNull(types.number),
    markPercent: types.maybeNull(types.number),
    passedAt: types.maybeNull(types.Date),
    passingPercent: types.maybeNull(types.number),
    startedTimestamp: types.maybeNull(types.Date),
    code: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    exerciseCount: types.maybeNull(types.number),
    order: types.maybeNull(types.number),
    // timer: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.Date),
    type: types.maybeNull(TypeModel),
    exercises: types.optional(
      types.array(types.late(() => types.reference(ExerciseModel))),
      []
    ),
    additionalContent: types.maybeNull(AdditionalContentModel),
  })
  .views((self) => ({
    get totalExercises(): number {
      return self.exercises.length;
    },
    get totalExercisesPassed(): number {
      return self.exercises.filter((exercise) => exercise.isPassed).length;
    },
    get wrongCompletedExercises(): number {
      return self.exercises.filter((exercise) => exercise.isWrong).length;
    },
    get sortedExercises(): Exercise[] {
      return self.exercises
        .slice()
        .sort((a, b) => Number(a.order) - Number(b.order));
    },
  }))
  .views((self) => ({
    /**
     * true если хотя бы одно упражнение пройдено верно и нет упражнений пройденных неверно
     */
    get isPassed(): boolean {
      if (self.totalExercises === 0) return false;

      return (
        self.totalExercisesPassed === self.totalExercises ||
        (self.totalExercisesPassed > 0 && self.wrongCompletedExercises === 0)
      );
    },
    /**
     * true если все упражнения пройдены верно
     */
    get isAllPassed(): boolean {
      return self.totalExercises === self.totalExercisesPassed;
    },
    get isWrong(): boolean {
      return self.wrongCompletedExercises > 0;
    },
    get totalSkippedExercises(): number {
      return (
        self.totalExercises -
        (self.totalExercisesPassed + self.wrongCompletedExercises)
      );
    },
  }))
  .views((self) => ({
    get firstExercise(): Exercise | undefined {
      return self.exercises.slice()[0];
    },
    getExercise(id: string | undefined): Exercise | undefined {
      return self.exercises.find((exercise) => exercise.id === id);
    },
    get firstNotPassedExercise(): Exercise | undefined {
      return self.sortedExercises.find((exercise) => !exercise.isPassed);
    },
  }));

type BlockModelType = Instance<typeof BlockModel>;
export interface Block extends BlockModelType {}
type BlockSnapshotType = SnapshotOut<typeof BlockModel>;
export type BlockSnapshot = BlockSnapshotType;
