import { Box, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { useStores } from "@packages/store/models";
import { Block } from "@packages/store/models/Block/Block";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { Cards, DataCards, VariantWithLink } from "components/Cards";
import { Icon, IconType } from "components/Icon";
import { Loading } from "components/Loading";
import { BlockItem } from "components/ModuleItem";
import { getCardType } from "utils/basic";

import { Body, BottomContainer, Container } from "./styledComponents";

export const ModalUnit = observer((): JSX.Element => {
  const store = useStores();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isOpen = true;
  const { id, unitId } = useParams();

  const handleClose = () => {
    navigate(-1);
  };

  const currentCourse = store?.course.getCourse(id);
  const selectedUnit = store?.unit.items.find((unit) => unit.id === unitId);

  const getVariantWithLink = (lesson: Lesson): VariantWithLink => {
    const { webinarRecordLink, currentStatus, webinarRoomLink } = lesson;

    if (currentStatus === "planned" && webinarRoomLink) {
      return { variant: "default", url: webinarRoomLink };
    }

    if (currentStatus === "completed" && webinarRecordLink) {
      return { variant: "success", url: webinarRecordLink };
    }

    return { variant: "cancel", url: "" };
  };
  const getModuleType = (moduleType: string): IconType => {
    const defaultType = "vocabulary";
    const moduleTypes: { [key: string]: IconType } = {
      Vocabulary: "vocabulary",
      Test: "test",
      Video: "video",
      Grammar: "grammar",
      Speaking: "speaking",
      Reading: "reading",
      Writing: "reading",
    };
    return moduleTypes[moduleType] || defaultType;
  };

  const getLessons = (): DataCards[] => {
    if (!selectedUnit) return [];
    const newLessons = selectedUnit?.allLessons?.map((lesson) => {
      return {
        id: lesson?.index,
        dateCard: lesson.startAtLocal || "",
        timeCard: "",
        status: lesson.currentStatus || "",
        typeButton: getVariantWithLink(lesson),
        disabledBtn: false,
        color: getCardType(lesson).color,
        typeIcon: getCardType(lesson).icon,
      };
    });

    return newLessons as DataCards[];
  };

  const lessons = getLessons();

  const UnitBlocks = ({ blocks }: { blocks: Block[] }): JSX.Element => {
    const blocksProps = blocks.map((block) => ({
      key: block.id,
      blockId: block.id,
      blockTitle: block.name ?? "",
      moduleType: block?.type?.code || "Unknown",
      totalExercises: block.totalExercises,
      completedExcercises: block.totalExercisesPassed,
      rating: 5,
      wrongCompletedExercises: block.wrongCompletedExercises,
      exerciseId: block.exercises?.[0]?.id,
      disabled:
        currentCourse?.isForTesting &&
        !selectedUnit?.isBlockAvailableForTesting(block.id),
    }));
    return (
      <>
        <Box
          sx={{
            display: lessons.length === 0 ? "block" : "none",
            marginBottom: "1rem",
            padding: "0px 16px",

            [theme.breakpoints.down("desktop")]: {
              display: "block",
            },
          }}
        >
          <Typography variant="h1">Exercises</Typography>
        </Box>
        {blocksProps.map(
          ({
            key,
            blockTitle,
            moduleType,
            totalExercises,
            completedExcercises,
            wrongCompletedExercises,
            blockId,
            exerciseId,
            disabled,
          }) => {
            return (
              <BlockItem
                key={key}
                blockTitle={blockTitle}
                completedExcercises={completedExcercises}
                totalExercises={totalExercises}
                moduleType={getModuleType(moduleType)}
                blockType={moduleType}
                wrongCompletedExercises={wrongCompletedExercises}
                courseId={selectedUnit?.course?.id || ""}
                unitId={unitId || ""}
                blockId={blockId}
                exerciseId={exerciseId}
                showRating={!store.auth.user?.isHR}
                disabled={disabled}
              />
            );
          }
        )}
      </>
    );
  };

  return (
    <Container disableScrollLock={false} open={isOpen} onClose={handleClose}>
      <Body theme={theme} noLessons={lessons.length === 0}>
        <Box
          sx={{
            flex: "1",
            flexWrap: "wrap",
            backgroundColor: "#fff",
            overflowY: "auto",
            height: "100%",
            border: lessons.length === 0 ? `0.07rem solid #E0E0E0` : "none",
            padding: lessons.length === 0 ? "1rem 0" : "0",
            borderRadius: "0.5rem",
            overflowX: "hidden",
            [theme.breakpoints.down("desktop")]: {
              border: `0.07rem solid #E0E0E0`,
              padding: "1rem 0",
            },
          }}
        >
          <Box
            sx={{
              padding: "0px 16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h1">{selectedUnit?.name}</Typography>
            <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
              <Icon
                color={theme.palette.custom.grey.dark}
                type="close"
                width={20}
                height={20}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: lessons.length === 0 ? "block" : "flex",
              justifyContent: "space-between",
              mt: "1.5rem",
              flexWrap: "wrap",
              minWidth: "10rem",
              [theme.breakpoints.down("desktop")]: {
                display: "block",
              },
            }}
          >
            <BottomContainer theme={theme} noLessons={lessons.length === 0}>
              <Cards
                unit={selectedUnit?.name || ""}
                iconType="elementary"
                exercisesCount={`${selectedUnit?.exercisesCount || 0} ${t(
                  "Module:ex"
                )}`}
                dataCard={lessons}
                iconColor={theme.palette.info.light}
                totalProgress={selectedUnit?.totalProgress || 0}
                resultsTest={selectedUnit?.testResults || 0}
                showTestResults={!store.auth.user?.isHR}
              />
            </BottomContainer>

            <Box
              sx={{
                flex: "1",
                height: "auto",
                zIndex: 10,
                minWidth: "10rem",
              }}
            >
              <Loading loading={!selectedUnit?.blocks.length}>
                <UnitBlocks blocks={selectedUnit?.blocks || []} />
              </Loading>
            </Box>
          </Box>
        </Box>
      </Body>
    </Container>
  );
});
