import * as Icons from "../../assets/icons/index";

export type IconType =
  | "exerciseCheckBtn"
  | "close"
  | "elementary"
  | "mic"
  | "next"
  | "reload"
  | "exerciseCheckBtnOn"
  | "exerciseCheckBtnOff"
  | "arrowRight"
  | "checkmark"
  | "play"
  | "stop"
  | "bxVolumeFull"
  | "words"
  | "bookMark"
  | "vocabulary"
  | "test"
  | "video"
  | "grammar"
  | "speaking"
  | "reading"
  | "elementaryGrey"
  | "bxLibrary"
  | "penGrey"
  | "exerciseSuccess"
  | "exerciseFail"
  | "exerciseSkip"
  | "profile"
  | "chevronDown";

export interface IconProps {
  type: IconType;
  color?: string;
  stroke?: string;
  width?: number | string;
  height?: number | string;
  onClick?: (e: React.MouseEvent) => void;
  sizeSquareIcon?: number | string;
}

type IIcon = {
  [key in IconType]: React.ReactElement;
};

export const Icon = ({
  type,
  color,
  stroke,
  onClick,
  width: iconWidth,
  height: iconHeight,
  sizeSquareIcon,
}: IconProps): JSX.Element => {
  const width = iconWidth ?? sizeSquareIcon;
  const height = iconHeight ?? sizeSquareIcon;

  const defaultProps = {
    stroke,
    color,
    onClick,
    width,
    height,
  };

  const icon: IIcon = {
    exerciseCheckBtn: <Icons.ExerciseCheckBtn {...defaultProps} />,
    close: <Icons.Close {...defaultProps} />,
    exerciseCheckBtnOn: <Icons.ExerciseCheckBtnOn {...defaultProps} />,
    exerciseCheckBtnOff: <Icons.ExerciseCheckBtnOff {...defaultProps} />,
    elementary: <Icons.Elementary {...defaultProps} />,
    mic: <Icons.Mic {...defaultProps} />,
    next: <Icons.Next {...defaultProps} />,
    reload: <Icons.Reload {...defaultProps} />,
    arrowRight: <Icons.ArrowRight {...defaultProps} />,
    checkmark: <Icons.CheckMark {...defaultProps} />,
    play: <Icons.Play {...defaultProps} />,
    stop: <Icons.Stop {...defaultProps} />,
    vocabulary: <Icons.Vocubalury {...defaultProps} />,
    bxVolumeFull: <Icons.BxVolumeFull {...defaultProps} />,
    words: <Icons.Words {...defaultProps} viewBox="0 0 32 32" />,
    bookMark: <Icons.BookMark {...defaultProps} />,
    test: <Icons.Test {...defaultProps} />,
    video: <Icons.Video {...defaultProps} />,
    grammar: <Icons.Grammar {...defaultProps} />,
    speaking: <Icons.Speaking {...defaultProps} />,
    reading: <Icons.Reading {...defaultProps} />,
    elementaryGrey: <Icons.ElementaryGrey {...defaultProps} />,
    bxLibrary: <Icons.BxLibrary {...defaultProps} />,
    penGrey: <Icons.PenGrey {...defaultProps} />,
    exerciseSuccess: <Icons.ExerciseSuccess {...defaultProps} />,
    exerciseFail: <Icons.ExerciseFail {...defaultProps} />,
    exerciseSkip: <Icons.ExerciseSkip {...defaultProps} />,
    profile: <Icons.Profile {...defaultProps} />,
    chevronDown: <Icons.ChevronDown {...defaultProps} />,
  };

  return icon[type];
};
