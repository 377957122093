import {
  ExerciseResultError,
  ExerciseErrorType,
} from "@packages/data/creativeTasks";

import { isInRange } from "./isInRange";
import { WordRange, WordHightlight } from "../types";

export function getWordHightlight(
  wordIndex: number,
  hoveredError?: ExerciseResultError,
  createRange?: WordRange,
  createType?: ExerciseErrorType
): WordHightlight {
  const isCreateRange = createRange && isInRange(createRange, wordIndex);

  if (isCreateRange) {
    return createType === ExerciseErrorType.COMMENT ? "comment" : "error";
  }

  if (hoveredError && isInRange(hoveredError, wordIndex)) {
    return hoveredError.errorTypeId === ExerciseErrorType.COMMENT
      ? "comment"
      : "error";
  }

  return "empty";
}
