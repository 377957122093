import { useEffect, useMemo } from "react";

import { Pagination } from "@jetspeak/jetclass-ui-lib";
import { Typography, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import { PivotReport } from "@packages/store/services/Api";

import { AttendanceReportsPivotTable } from "./components";
import { Loading } from "./components/Loading";
import { filterLessonsByDateRange, getFilteredStudentsByName } from "./helpers";
import { DateRange } from "./types";

interface AttendanceReportGroupsProps {
  page: number;
  loading: boolean;
  groups: LearningGroup[];
  student?: string;
  dateRange?: DateRange;
  groupsPerPage?: number;
  maximumGroups?: number;
  onlyTeachersLessons: boolean;
  onPageChange?: (_: number) => void;
}

export const AttendanceReportGroups = observer(
  ({
    page,
    loading,
    groups,
    student,
    dateRange,
    groupsPerPage = 10,
    maximumGroups = 0,
    onlyTeachersLessons,
    onPageChange,
  }: AttendanceReportGroupsProps): JSX.Element => {
    const { t } = useTranslation();

    const { auth } = useStores();

    const isGroupsEmpty = groups.length === 0;
    const showOnlyTeacherId =
      onlyTeachersLessons && auth.user?.isTeacher ? auth.user?.id : undefined;

    const handleChangePage = (newPage: number): void => {
      if (newPage !== null) {
        onPageChange?.(newPage);
      }
    };

    useEffect(() => {
      if (!groups) {
        return;
      }

      window.scrollTo(0, 0);
    }, [page, groups]);

    const isPagination = groups.length > 0;

    const isAllLessonsEmpty = useMemo(() => {
      if (isGroupsEmpty) {
        return false;
      }
      const visibleGroups = groups.filter((group) => {
        const { pivotReport = {} } = group;

        const { students = [], lessons } = pivotReport as PivotReport;

        const completedLessons = lessons.filter(
          ({ isCompleted }) => isCompleted
        );

        const filteredStudents = getFilteredStudentsByName(students, student);
        const filteredLessons = filterLessonsByDateRange(
          completedLessons,
          dateRange
        );

        return filteredLessons.length > 0 && filteredStudents.length > 0;
      });

      return visibleGroups.length === 0;
    }, [groups, dateRange, student, isGroupsEmpty]);

    return (
      <Loading loading={loading}>
        <>
          {isGroupsEmpty && (
            <Box mt="1rem">
              <Typography variant="h3">
                {t("ReportsAttendance:EmptyGroupAndCompany")}
              </Typography>
            </Box>
          )}
          {isAllLessonsEmpty && (
            <Box mt="1rem">
              <Typography variant="h3">
                {t("ReportsAttendance:EmptyLessons")}
              </Typography>
            </Box>
          )}
          <Box>
            {groups.map((group) => (
              <AttendanceReportsPivotTable
                key={`report-group-${group.id}`}
                group={group}
                studentFilter={student}
                dateRangeFilter={dateRange}
                teacherId={showOnlyTeacherId}
              />
            ))}
          </Box>
          {isPagination && (
            <Pagination
              currPage={page}
              total={maximumGroups}
              perPage={groupsPerPage}
              onChange={handleChangePage}
            />
          )}
        </>
      </Loading>
    );
  }
);
