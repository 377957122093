import { ExerciseResultCorrection } from "@packages/data/creativeTasks";

import { isInRange } from "./isInRange";
import { WordRange } from "../types";

function fillGapsWithBounds(
  start: number,
  end: number,
  source: [number, number][]
): [number, number][] {
  const intervals = [...source];
  intervals.push([start, start - 1]);
  intervals.push([end + 1, end]);

  intervals.sort((a, b) => a[0] - b[0]);

  const result: [number, number][] = [];
  let currentStart = start;

  for (const interval of intervals) {
    const [start, end] = interval;
    if (start > currentStart) {
      result.push([currentStart, start - 1]);
    }

    currentStart = Math.max(currentStart, end + 1);
  }

  return result;
}

function mergeIntervals(source: [number, number][]): [number, number][] {
  const intervals = source.sort((a: number[], b: number[]) => a[0] - b[0]);
  const result = [[intervals[0][0], intervals[0][1]]];

  for (let i = 1; i < intervals.length; i++) {
    if (intervals[i][0] <= result[result.length - 1][1]) {
      result[result.length - 1][1] = Math.max(
        result[result.length - 1][1],
        intervals[i][1]
      );
    } else {
      result.push(intervals[i]);
    }
  }
  return result as [number, number][];
}

export function getWordChunks(
  words: string[],
  ranges: WordRange[]
): ExerciseResultCorrection[] {
  if (!ranges.length) {
    return [
      {
        currentId: "",
        startPosition: 0,
        endPosition: words.length - 1,
        text: words.join(" "),
      },
    ];
  }
  const sortedRanges = [...ranges].sort((prev, next) =>
    prev.startPosition > next.startPosition ? 1 : -1
  );
  const intervals = mergeIntervals(
    sortedRanges.map(({ startPosition, endPosition }) => [
      startPosition,
      endPosition,
    ])
  );
  const gaps = fillGapsWithBounds(0, words.length, intervals);

  const wordIntervals = [...intervals, ...gaps].sort(
    (prev, next) => prev[0] - next[0]
  );

  let startIndex = 0;

  return wordIntervals.map((interval) => {
    const [startPosition, endPosition] = interval;
    const range = { startPosition, endPosition };
    startIndex = startPosition;
    const filteredWords = words.filter((_: string, index: number) =>
      isInRange(range, index)
    );
    return {
      currentId: "",
      startPosition: startIndex,
      endPosition: startIndex + filteredWords.length - 1,
      text: filteredWords.join(" "),
    };
  });
}
