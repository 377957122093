import { DistributorReportType, Report } from "./types";

const reportTypesToDownloadViaDownloadUrl = [
  DistributorReportType.KnowledgeLevels,
];

export const downloadXlsx = (
  report: Pick<Report, "name" | "type" | "xlsxPath" | "downloadUrl">,
  form: "short" | "long" = "long"
) => {
  let url = report.xlsxPath;

  if (form === "short") {
    url += "?type=short";
  }

  if (reportTypesToDownloadViaDownloadUrl.includes(report.type)) {
    url = report.downloadUrl;
  }

  const link = document.createElement("a");
  link.download = report.name + ".xlsx";
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
