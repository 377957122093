import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const KnowledgeLevelModel = types.model({
  code: types.maybeNull(types.string),
  id: types.identifier,
  name: types.maybeNull(types.string),
});

type KnowledgeLevelModelType = Instance<typeof KnowledgeLevelModel>;
export type KnowledgeLevel = KnowledgeLevelModelType;
type KnowledgeLevelModelSnapshotType = SnapshotOut<typeof KnowledgeLevelModel>;
export type KnowledgeLevelSnapshot = KnowledgeLevelModelSnapshotType;
