import styles from "./CorrectedWord.module.scss";

interface CorrectedWordProps {
  text: string;
  onClear?: () => void;
}

export const CorrectedWord = (props: CorrectedWordProps) => {
  const { text, onClear } = props;
  return (
    <span className={styles.word}>
      {text}
      <span className={styles["word-clear"]} onClick={onClear}>
        X
      </span>
    </span>
  );
};
