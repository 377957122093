import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { camelCase } from "change-case";

const isDev = process.env.NODE_ENV === "development";
const API_URL = isDev ? process.env.BACKEND_URL : "";

export const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

const transformObjectKeys = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  }

  let newObj = obj;

  if (typeof newObj === "object") {
    if (obj instanceof Array) {
      newObj = obj.map(transformObjectKeys);
    } else {
      newObj = {};

      Object.keys(obj).forEach((key) => {
        if (key === "id" || key.endsWith("id")) {
          newObj[camelCase(key)] = transformObjectKeys(String(obj[key]));
        } else {
          newObj[camelCase(key)] = transformObjectKeys(obj[key]);
        }
      });
    }
  }

  return newObj;
};

api.interceptors.response.use((res) => ({
  ...res,
  data: transformObjectKeys(res.data),
}));

export const createQueryClient = () => {
  const queryClient = new QueryClient();

  const changeLanguage = (language: string) => {
    api.defaults.headers.common["X-Jetclass-Language"] = language;
    queryClient.invalidateQueries();
  };

  return { queryClient, changeLanguage };
};
