/* eslint-disable @typescript-eslint/no-wrapper-object-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import { ValueWithLabel } from "@packages/store/services/Api";

import { Checkbox } from "components/Checkbox";
import { Icon } from "components/Icon";
import { Table } from "components/Table";

import { InlineButton, Row } from "./styledComponents";

export interface GroupsListProps {
  groupList: ValueWithLabel[];
  selectedGroups: string[];
  loading?: boolean;
  onGroupListChange: (_: ValueWithLabel[]) => void;
  onSelectedGroupsChange: (_: string[]) => void;
}

interface GroupTable {
  [key: string]: unknown;
  group: ValueWithLabel;
}

export const GroupsList = ({
  groupList,
  selectedGroups,
  loading = false,
  onGroupListChange,
  onSelectedGroupsChange,
}: GroupsListProps): JSX.Element => {
  const { t } = useTranslation();
  const [data, setData] = useState<GroupTable[]>([]);

  const count = selectedGroups.length;

  const isGroupSelected = useCallback(
    (group: string) => {
      return selectedGroups.includes(group);
    },
    [selectedGroups]
  );

  const selectGroup = useCallback(
    (group: string) => () => {
      if (isGroupSelected(group)) {
        onSelectedGroupsChange(
          selectedGroups.filter((value) => value !== group)
        );
      } else {
        onSelectedGroupsChange([...selectedGroups, group]);
      }
    },
    [isGroupSelected, onSelectedGroupsChange, selectedGroups]
  );

  const removeGroup = useCallback(
    (group: string) => (e: React.MouseEvent) => {
      e.stopPropagation();

      if (isGroupSelected(group)) {
        selectGroup(group)();
      }

      const filteredGroups = groupList.filter(({ value }) => value !== group);

      onGroupListChange(filteredGroups);
    },
    [groupList, isGroupSelected, onGroupListChange, selectGroup]
  );

  const selectAll = () => {
    const allGroupsSelected = groupList.length === selectedGroups.length;

    if (allGroupsSelected) {
      onSelectedGroupsChange([]);
    } else {
      onSelectedGroupsChange(groupList.map(({ value }) => value));
    }
  };

  const getBackgroundColor = useCallback(
    (group: string, defaultColor = "transparent") => {
      return isGroupSelected(group) ? "#D7D7D7" : defaultColor;
    },
    [isGroupSelected]
  );

  useEffect(() => {
    setData(groupList.map((group) => ({ group })).reverse());
  }, [groupList, setData]);

  const columns = useMemo(
    () => [
      {
        Header: t("CreateReport:GroupListGroup"),
        accessor: "group",
        filter: "group",
        Cell: ({ value: { value, label } }: Cell<String>) => (
          <Row alignItems="center" onClick={selectGroup(value)}>
            <Checkbox checked={isGroupSelected(value)} />
            <Typography>{label}</Typography>
            <Row />
            <Icon
              type="close"
              width={15}
              height={15}
              color="red"
              onClick={removeGroup(value)}
            />
          </Row>
        ),
        cellStyles: (cell: Cell) => ({
          background: getBackgroundColor(cell.value.value),
          width: 0,
        }),
      },
    ],
    [getBackgroundColor, isGroupSelected, removeGroup, selectGroup, t]
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb="1rem">
        <Typography>{t("CreateReport:GroupListTitle", { count })}</Typography>

        <Box display="flex" gap="1rem">
          <InlineButton color="#5783F1" onClick={selectAll}>
            {t("CreateReport:GroupListSelectAll")}
          </InlineButton>
        </Box>
      </Box>
      <Box position="relative">
        <Table columns={columns} data={data} count={10} />
        {loading && (
          <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};
