import { useContext } from "react";

import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import styles from "./Actions.module.scss";
import { Button } from "../../Button";
import { UserRoleContext } from "../utils/context";
import { UserRole } from "../utils/types";

interface ActionsProps {
  onSubmit?: () => void;
  onUpdate?: () => void;
  disabled?: boolean;
  updateLoading?: boolean;
  submitLoading?: boolean;
}

export const Actions = observer((props: ActionsProps) => {
  const { onSubmit, onUpdate, disabled, updateLoading, submitLoading } = props;

  const { t } = useTranslation();

  const { currentRole } = useContext(UserRoleContext);

  const isTeacher = currentRole === UserRole.TEACHER;

  const updateText = isTeacher
    ? t("CreativeTasks:UpdateTaskTeacher")
    : t("CreativeTasks:UpdateTaskStudent");
  const submitText = isTeacher
    ? t("CreativeTasks:SubmitTaskTeacher")
    : t("CreativeTasks:SubmitTaskStudent");

  return (
    <div className={styles.container}>
      <Button
        variant="soft-secondary"
        onClick={onUpdate}
        disabled={disabled || updateLoading}
      >
        {updateText}
      </Button>
      <Button
        variant="main"
        onClick={onSubmit}
        disabled={disabled || submitLoading}
      >
        {submitText}
      </Button>
    </div>
  );
});
