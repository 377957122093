import { useMemo } from "react";

import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import {
  useGetCreativeTasks,
  ExerciseResult,
  ExerciseResultStatus,
} from "@packages/data/creativeTasks";

import { Task } from "./Task";
import styles from "./TaskList.module.scss";
import { useUser } from "../../hooks";
import { isStringsSimilar } from "../../utils/helpers";
import { List } from "../List";
import { Loading } from "../Loading";

interface TaskListProps {
  containerClassName?: string;
  onShowDetails?: (_: string) => void;
  nameFilter?: string;
  statusFilter?: ExerciseResultStatus;
}

const RESULTS_PER_PAGE = 6;

export const TaskList = observer((props: TaskListProps) => {
  const { containerClassName, onShowDetails, nameFilter, statusFilter } = props;

  const { t } = useTranslation("CreativeTasks");

  const containerClasses = cn(styles.container, containerClassName);

  const { isTeacher = false } = useUser();

  const { data, isLoading } = useGetCreativeTasks({
    role: isTeacher ? "teacher" : "student",
  });

  const filteredResults = useMemo(() => {
    if (!data) {
      return [];
    }

    return data
      .filter((result) => {
        if (!nameFilter) return true;

        return isStringsSimilar(result.userName, nameFilter);
      })
      .filter((result) => {
        if (!statusFilter) return true;

        return result.status === statusFilter;
      });
  }, [data, nameFilter, statusFilter]);

  const renderTask = (task: ExerciseResult) => (
    <Task key={task.id} data={task} onButtonClick={onShowDetails} />
  );

  return (
    <Loading loading={isLoading}>
      <div className={containerClasses}>
        <List
          itemsPerPage={RESULTS_PER_PAGE}
          data={filteredResults}
          renderItem={renderTask}
          emptyClassName={styles.empty}
          emptyText={t("EmptyList") ?? ""}
        />
      </div>
    </Loading>
  );
});
