import { createContext } from "react";

import { ExerciseResultCorrection } from "@packages/data/creativeTasks";

export interface CorrectionCreateContextProps {
  editableCorrection?: ExerciseResultCorrection;
  setEditableCorrection?: (_: ExerciseResultCorrection | undefined) => void;
}

export const CorrectionCreateContext =
  createContext<CorrectionCreateContextProps>({});
