import { useCallback, useMemo } from "react";

import { AnswerTextContent } from "./AnswerTextContent";
import { Text } from "../../Text";
import { ErrorSelectionWord, ErrorSelectionWrapper } from "../ErrorSelection";
import { WordSpace } from "../WordSpace";

interface AnswerTextTeacherProps {
  text?: string;
}

export const AnswerTextTeacher = (props: AnswerTextTeacherProps) => {
  const { text = "" } = props;

  const words = useMemo(
    () => text.split(".").join(". ").replace(/\s+/g, " ").split(" "),
    [text]
  );

  const renderWord = useCallback(
    (word: string, index: number) => (
      <ErrorSelectionWord index={index}>
        {word}
        <WordSpace />
      </ErrorSelectionWord>
    ),
    []
  );

  return (
    <Text variant="text-2-regular" color="base-d">
      <ErrorSelectionWrapper>
        <AnswerTextContent words={words} renderWord={renderWord} />
      </ErrorSelectionWrapper>
    </Text>
  );
};
