function isDateValid(dateStr: string) {
  return !isNaN(new Date(dateStr) as any);
}

export function convertExerciseTimeToDate(
  time: string | number | null
): Date | null {
  if (!time) return null;

  if (typeof time === "number") {
    return new Date(time);
  }

  if (isDateValid(time)) {
    return new Date(time);
  }

  const [dateStr, timeStr] = time.split(" | ");

  // Split the date into day, month, and year components
  const [day, month, year] = dateStr.split(".");

  // Split the time into hours and minutes components
  const [hours, minutes] = timeStr.split(":");

  // Create a Date object with the parsed components
  const resultDate = new Date(+year, +month - 1, +day, +hours, +minutes);

  return resultDate;
}
