import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  CSSProperties,
} from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";

import { ExerciseErrorType } from "@packages/data/creativeTasks";

import styles from "./ErrorCreateMenu.module.scss";
import { Button, ButtonType } from "../../Button";
import { Icon } from "../../Icon";
import { getErrorIcon } from "../utils/helpers";
import { useOffset } from "../utils/hooks";

interface ErrorCreateMenuEditorProps {
  type: ExerciseErrorType;
  comment: string;
  onChangeError: () => void;
  onAddError: () => void;
  onCommentInput: (_: string) => void;
  onSave: () => void;
}

export const ErrorCreateMenuEditor = (props: ErrorCreateMenuEditorProps) => {
  const { type, comment, onChangeError, onAddError, onCommentInput, onSave } =
    props;

  const { t } = useTranslation();

  const { containerRef, leftOffset, topOffset } = useOffset();

  const containerStyle: CSSProperties = useMemo(
    () => ({
      left: `${leftOffset}px`,
      bottom: `calc(100% + ${topOffset}px)`,
    }),
    [leftOffset, topOffset]
  );

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const editorClasses = cn(styles.container, {
    [styles.error]: type !== ExerciseErrorType.COMMENT,
    [styles.comment]: type === ExerciseErrorType.COMMENT,
  });

  const saveBtnVariant: ButtonType =
    type === ExerciseErrorType.COMMENT ? "secondary" : "main";

  const editorHeaderClasses = cn(styles["editor-header"], {
    [styles.error]: type !== ExerciseErrorType.COMMENT,
    [styles.comment]: type === ExerciseErrorType.COMMENT,
  });

  const titles: Record<ExerciseErrorType, string> = useMemo(
    () => ({
      [ExerciseErrorType.COMMENT]: t("CreativeTasks:Comment"),
      [ExerciseErrorType.GRAMMAR]: t("CreativeTasks:Grammar"),
      [ExerciseErrorType.LEXICAL]: t("CreativeTasks:Lexical"),
    }),
    [t]
  );

  const icon = getErrorIcon(type);

  const handleCommentInput = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      const target = event.target as HTMLTextAreaElement;
      onCommentInput(target.value);
    },
    [onCommentInput]
  );

  useEffect(() => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    requestAnimationFrame(() => {
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    });
  }, [inputRef.current]);

  return (
    <div ref={containerRef} className={editorClasses} style={containerStyle}>
      <div className={editorHeaderClasses}>
        <div className={styles["editor-header-title"]}>
          <Icon containerClassName={styles.icon} icon={icon} />
          <label>{titles[type]}</label>
        </div>

        <div className={styles["editor-header-actions"]}>
          <Icon
            containerClassName={styles["editor-header-action"]}
            icon="edit"
            onClick={onChangeError}
          />
          <Icon
            containerClassName={styles["editor-header-action"]}
            icon="add"
            onClick={onAddError}
          />
        </div>
      </div>
      <textarea
        ref={inputRef}
        className={styles["editor-input"]}
        onChange={handleCommentInput}
        value={comment}
      />
      <Button variant={saveBtnVariant} onClick={onSave}>
        {t("CreativeTasks:SaveError")}
      </Button>
    </div>
  );
};
