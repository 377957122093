import { useState } from "react";

import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";
import { WordFilterStore } from "@packages/store/models/WordFilter/WordFilterStore";

import { ButtonWithIcon } from "components/ButtonWithIcon";
import { WordsFilter } from "components/WordsFilter";
import { emptyFilterItem } from "constants/constants";

import {
  DesktopContainer,
  ModalContainer,
  TabletContainer,
  FilterContainer,
} from "./styledComponents";

export interface WordFilterTypeItem {
  label: string;
  value: WordFilterStore["selectedType"];
}

export const MyWordsFilter = observer((): JSX.Element => {
  const { t } = useTranslation();

  const store = useStores();
  const {
    unit: unitStore,
    course: courseStore,
    wordFilter,
    word: wordStore,
  } = store;

  const {
    fromUnit,
    addedToMe,
    selectedCourse,
    selectedUnit,
    selectedType,
    rememberAddedToMe,
    rememberFromUnit,
    rememberSelectedCourse,
    rememberSelectedUnit,
    rememberSelectedType,
    clear,
  } = wordFilter;

  const [mobileFilterVisible, setMobileFilterVisible] = useState(false);

  const showMobileFilter = () => {
    setMobileFilterVisible(true);
  };

  const closeMobileFilter = () => {
    setMobileFilterVisible(false);
  };

  const words = wordStore.items;

  const coursesSource = courseStore.items
    .filter((course) => {
      const courseUnits = course.getUnits().map((unit) => +unit.id);
      const courseWords = words.filter((word) => {
        return courseUnits.includes(word.courseUnitId ?? 0);
      });
      return courseWords.length;
    })
    .map((course) => ({
      value: course.id ?? "",
      label: course.name ?? "",
    }));

  const courses = [
    {
      value: emptyFilterItem,
      label: t("MyWords:CourseNotSelected"),
    },
    ...coursesSource,
  ];

  const unitsSource = unitStore.items
    .filter((unit) => {
      return unit.course?.id === selectedCourse;
    })
    .filter((unit) => {
      return words.map((word) => word.courseUnitId).includes(+unit.id);
    })
    .map((unit) => ({
      value: unit.id,
      label: unit.name ?? "",
    }));

  const units = [
    {
      value: emptyFilterItem,
      label: t("MyWords:UnitNotSelected"),
    },
    ...unitsSource,
  ];

  const types: WordFilterTypeItem[] = [
    {
      label: t("MyWords:FilterUnlearned"),
      value: "unlearned",
    },
    {
      label: t("MyWords:FilterAllWords"),
      value: "all",
    },
    {
      label: t("MyWords:FilterLearned"),
      value: "learned",
    },
  ];

  const handleCourseChange = (value: string) => {
    rememberSelectedCourse(value);
  };

  const handleUnitChange = (value: string) => {
    rememberSelectedUnit(value);
  };

  const handleTypeChange = (value: string) => {
    rememberSelectedType(value as WordFilterStore["selectedType"]);
  };

  const Filter = (
    <FilterContainer>
      <WordsFilter
        fromUnit={fromUnit}
        addedToMe={addedToMe}
        courses={courses}
        units={units}
        types={types}
        selectedCourse={selectedCourse}
        selectedUnit={selectedUnit}
        selectedType={selectedType}
        onModalClose={closeMobileFilter}
        onClear={clear}
        onFromUnitToggle={rememberFromUnit}
        onAddedMeToggle={rememberAddedToMe}
        onCourseChange={handleCourseChange}
        onUnitChange={handleUnitChange}
        onTypeChange={handleTypeChange}
      />
    </FilterContainer>
  );

  return (
    <>
      <DesktopContainer>{Filter}</DesktopContainer>
      <TabletContainer>
        <ButtonWithIcon
          onClick={showMobileFilter}
          title={t("MyWords:FilterTitle")}
        />
        <ModalContainer open={mobileFilterVisible} onClose={closeMobileFilter}>
          {Filter}
        </ModalContainer>
      </TabletContainer>
    </>
  );
});
