/* eslint-disable @typescript-eslint/no-shadow */
import { useMemo, useState } from "react";

import { Box, Typography } from "@mui/material";
import { isNull } from "lodash";
import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";
import { AnswerModel } from "@packages/store/models/ExerciseAnswer/Answer";
import { Answer } from "@packages/store/models/Question/Answer";

import { AudioRecorder } from "../../../../../../components/AudioRecorder";
import { ChatItem, DialogChat } from "../../../../../../components/DialogChat";
import { ChatHelper } from "../../../../../../components/DialogChat/ChatHelper";
import { PossibleDialogAnswers } from "../../../../../../components/DialogChat/PossibleDialogAnswers";
import { Loading } from "../../../../../../components/Loading";
import { VideoPlayer } from "../../../../../../components/VideoPlayer";
import { ensure } from "../../../../../../helpers/ensure";
import { getFormDataFromAudio } from "../../../../../../helpers/getFormDataFromAudio";
import { getMediaPlayerOptions } from "../../../../../../helpers/getMediaPlayerOptions";
import { useFetch } from "../../../../../../hooks/useFetch";
import { SubExerciseComponentProps } from "../../../../../../types";

const MAX_NUMBER_OF_ATTEMPTS = 3;

export const Dialog = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const rootStore = useStores();
    const { t } = useTranslation();
    // id распознанного ответа для того чтобы можно было его подтвердить
    const [recognizedId, setRecognizedId] = useState<string | null>(null);
    // Иногда плохо распознаётся ответ и нужно дать возможность просто его выбрать
    const [numberOfAttempts, setNumberOfAttempts] = useState(0);
    const [recognitionError, setRecognitionError] = useState<string | null>(
      null
    );
    // Это текущий ответ, его нужно в разных местах дополнять поэтому он тут
    const [answer, setAnswer] = useState(AnswerModel.create());

    const { id: exerciseId, answer: exerciseAnswer, questions } = exercise;

    const {
      firstUnfilledQuestion,
      isFilled,
      questions: answerQuestions,
    } = ensure(exerciseAnswer);

    const {
      id: questionId = "",
      firstAnswer,
      addAnswer,
      removeAnswer,
    } = firstUnfilledQuestion || {};

    const currentQuestionIndex = !isFilled
      ? questions.findIndex(({ id }) => id === questionId)
      : questions.length - 1;
    const currentQuestion = questions[currentQuestionIndex];

    const { media = [], answers: possibleAnswer = [] } = currentQuestion || {};

    const videoPlayerOptions = useMemo(
      () => getMediaPlayerOptions(media),
      [media]
    );

    const chatItems: ChatItem[] = [];
    for (let i = 0; i <= currentQuestionIndex; i += 1) {
      chatItems.push({
        text: questions[i]?.text || "",
        audioSrc: questions[i]?.media[0].url || "",
      });

      if (answerQuestions[i]?.firstAnswer?.isFilled) {
        chatItems.push({
          text: answerQuestions[i]?.firstAnswer?.text || "",
          audioSrc: answerQuestions[i]?.firstAnswer?.recordObjectURL || "",
        });
      }
    }

    const { fetch: recognizeRecording, loading } = useFetch(
      (formData: FormData) => {
        return rootStore.environment.api.preprocessSpeakingQuestion(
          exerciseId,
          questionId,
          formData
        );
      },
      ({ comparationPercent, closestCorrectAnswer }) => {
        if (firstAnswer) {
          removeAnswer?.(firstAnswer);
        }

        const recognizedAnswer = possibleAnswer.find(
          ({ id }) => id === closestCorrectAnswer?.toString()
        );

        if (
          !isNull(comparationPercent) &&
          !isNull(closestCorrectAnswer) &&
          recognizedAnswer
        ) {
          setRecognizedId(recognizedAnswer.id);
          answer.setText(recognizedAnswer.text || "");
          return;
        }

        setRecognitionError(t("Exercise:DialogExerciseRecognitionError"));
      },
      () => setRecognitionError(t("Exercise:DialogExerciseRecognitionError")),
      () => setNumberOfAttempts((p) => p + 1)
    );

    const handleStopRecording = (blob: Blob) => {
      answer.setRecordObjectURL(URL.createObjectURL(blob));

      const formData = getFormDataFromAudio(blob);
      recognizeRecording(formData);
    };

    const handleStartRecording = () => {
      if (firstAnswer) {
        removeAnswer?.(firstAnswer);
      }

      setRecognitionError(null);
      setRecognizedId(null);
    };

    const handleReset = () => {
      setRecognizedId(null);
      setRecognitionError(null);
      setNumberOfAttempts(0);
      setAnswer(AnswerModel.create());
    };

    const handleConfirm = () => {
      if (firstAnswer) {
        removeAnswer?.(firstAnswer);
      }

      addAnswer?.(answer);
      handleReset();
    };

    const handleChoice = ({ text }: Answer) => {
      if (text) {
        answer.setText(text);
        answer.setRecordObjectURL("");

        handleConfirm();
      }
    };

    return (
      <>
        {!isFilled && <VideoPlayer options={videoPlayerOptions} />}

        <DialogChat items={chatItems} containerStyles={{ m: "1rem 0" }} />

        {!isFilled && (
          <ChatHelper
            legend={t("Exercise:DialogExerciseHelperLegend")}
            variants={[
              t("Exercise:DialogExerciseFirstHelper"),
              t("Exercise:DialogExerciseSecondHelper"),
              t("Exercise:DialogExerciseThirdHelper"),
            ]}
            currentVariant={
              // eslint-disable-next-line no-nested-ternary
              numberOfAttempts === MAX_NUMBER_OF_ATTEMPTS
                ? 2
                : recognizedId
                ? 1
                : 0
            }
          />
        )}

        {isFilled && (
          <ChatHelper
            legend={t("Exercise:DialogExerciseFinishHelperLegend")}
            text={t("Exercise:DialogExerciseFinishHelper")}
          />
        )}

        {!isFilled && (
          <PossibleDialogAnswers
            possibleAnswers={possibleAnswer}
            recognizedAnswerId={recognizedId}
            choiceVariant={numberOfAttempts === MAX_NUMBER_OF_ATTEMPTS}
            onConfirm={handleConfirm}
            onChoice={handleChoice}
            onReset={handleReset}
          />
        )}

        {!isFilled && numberOfAttempts !== MAX_NUMBER_OF_ATTEMPTS && (
          <Box
            sx={{
              backgroundColor: "custom.grey.menu-tile-inactive",
              padding: "10px",
              borderRadius: "0.5rem",
            }}
          >
            <Loading loading={loading} wrapperStyles={{ p: 0 }}>
              <AudioRecorder
                onStartRecording={handleStartRecording}
                onStopRecording={handleStopRecording}
                maxRecorderTimeInSeconds={120}
              />

              <Typography variant="smallText">{recognitionError}</Typography>
            </Loading>
          </Box>
        )}
      </>
    );
  }
);
