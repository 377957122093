import { ChangeEvent, useMemo } from "react";

import { Input, Select } from "@jetspeak/jetclass-ui-lib";
import cn from "classnames";
import uniq from "lodash/uniq";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import {
  useGetCreativeTasks,
  ExerciseResultStatus,
} from "@packages/data/creativeTasks";

import styles from "./TasksFilters.module.scss";
import { useUser } from "../../hooks";
import { isStringsSimilar } from "../../utils/helpers";
// import { Input } from "../Input";

interface TasksFiltersProps {
  containerClassName?: string;
  name: string;
  onNameChange: (value: string) => void;
  status?: ExerciseResultStatus;
  onStatusChange: (value: ExerciseResultStatus | undefined) => void;
}

export const TasksFilters = observer((props: TasksFiltersProps) => {
  const { containerClassName, name, status, onNameChange, onStatusChange } =
    props;

  const { t } = useTranslation();

  const { isTeacher } = useUser();

  const { data = [] } = useGetCreativeTasks({
    role: isTeacher ? "teacher" : "student",
  });

  const namePlaceholder = isTeacher
    ? t("CreativeTasks:StudentSearch")
    : t("CreativeTasks:TeacherSearch");

  const handleStatusChange = (status: string | undefined) => {
    onStatusChange(status as ExerciseResultStatus);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    onNameChange(event.target.value);
  };

  const suggestions = useMemo(() => {
    return uniq(data.map(({ userName }) => userName)).filter((userName) => {
      return isStringsSimilar(userName, name);
    });
  }, [data, name]);

  const statuses = useMemo<
    { value: ExerciseResultStatus | undefined; label: string }[]
  >(() => {
    return [
      {
        label: t("CreativeTasks:AllStatuses"),
        value: undefined,
      },
      {
        label: t("CreativeTasks:Checked"),
        value: "checked",
      },
      {
        label: t("CreativeTasks:NewCheck"),
        value: "new_check",
      },
    ];
  }, [t]);

  const containerClasses = cn(styles.container, containerClassName);

  return (
    <form className={containerClasses}>
      <Input
        type="search"
        placeholder={namePlaceholder}
        value={name}
        suggestions={suggestions}
        // onValueChange={onNameChange}
        onChange={handleNameChange}
      />
      <Select
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        items={statuses}
        value={status}
        onChange={handleStatusChange}
        placeholder={t("CreativeTasks:AllStatuses") ?? ""}
        required
      />
    </form>
  );
});
