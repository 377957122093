import { ExerciseResultError } from "@packages/data/creativeTasks";

export function isWordContextVisible(
  wordIndex: number,
  hoveredWordIndex: number,
  hoveredWordIndexes: number[],
  hoveredError?: ExerciseResultError
): boolean {
  if (!hoveredWordIndexes.includes(wordIndex)) {
    return false;
  }

  if (hoveredWordIndex === wordIndex) {
    return true;
  }

  if (hoveredWordIndex !== -1) {
    return false;
  }

  if (hoveredError?.startPosition == wordIndex) {
    return true;
  }

  return false;
}
