import {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  SyntheticEvent,
} from "react";

import { Box, Slider, Theme, SxProps, ButtonBase } from "@mui/material";

import { Icon } from "../Icon";

interface AudioRecorderProps {
  src: string;
  timeLine?: boolean;
  time?: boolean;
  buttonStyles?: SxProps<Theme> | undefined;
}

const calculateTime = (secs: number) => {
  const minutes = Math.floor(secs / 60);
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${returnedSeconds}`;
};

export const Audio = ({
  src,
  timeLine = true,
  time = true,
}: AudioRecorderProps): JSX.Element | null => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playState, setPlayState] = useState<"pause" | "play">("pause");

  useLayoutEffect(() => {
    if (!audioRef.current) return;

    const audio = audioRef.current;

    /*
      В этой функции нужны такие странные махинации, потому что
      иногда длительность записи определяется, как бесконечность.
      И я не знаю как это работает....
    */
    const onLoadedMetadata = (e: any) => {
      const duration = Math.floor(e.target.duration);

      if (duration !== Infinity) {
        setDuration(duration);

        audio.currentTime = 0;
        audio.removeEventListener("timeupdate", onLoadedMetadata);
      } else {
        audio.currentTime = 1e101;
        audio.addEventListener("timeupdate", onLoadedMetadata);
      }
    };

    const ontimeUpdate = (e: any) =>
      setCurrentTime(Math.floor(e.target.currentTime));

    audio.addEventListener("loadedmetadata", onLoadedMetadata);
    audio.addEventListener("timeupdate", ontimeUpdate);

    // eslint-disable-next-line consistent-return
    return () => {
      audio.removeEventListener("loadedmetadata", onLoadedMetadata);
      audio.removeEventListener("timeupdate", ontimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (currentTime === duration) {
      if (!audioRef.current) return;

      setPlayState("pause");
    }
  }, [currentTime, duration]);

  const handleClick = () => {
    if (!audioRef.current) return;

    const audio = audioRef.current;

    if (playState === "play") {
      audio.pause();
    } else {
      if (currentTime === duration) {
        setCurrentTime(0);
      }
      audio.play();
    }
  };

  const handleChange = (_: Event, value: number | number[]) => {
    if (typeof value !== "number") return;

    setCurrentTime(value);

    if (!audioRef.current) return;

    audioRef.current.currentTime = value;
  };

  const handlePlay = (e: SyntheticEvent) => {
    setPlayState("play");

    document.body.querySelectorAll("audio").forEach((audio) => {
      if (audio !== e.target) {
        audio.pause();
      }
    });
  };

  const handlePause = () => {
    setPlayState("pause");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          maxWidth: "369px",
        }}
      >
        <audio
          src={src}
          preload="metadata"
          ref={audioRef}
          onPlay={handlePlay}
          onPause={handlePause}
        />

        <ButtonBase onClick={handleClick} sx={{ borderRadius: "50%", mr: 1 }}>
          {playState === "play" ? <Icon icon="stop" /> : <Icon icon="play" />}
        </ButtonBase>

        {timeLine && (
          <Slider
            sx={{
              marginRight: "10px",
              height: "6px",
              "& .MuiSlider-track": {
                color: "info.dark",
              },
              "& .MuiSlider-rail": {
                color: "#54b2de",
              },
              "& .MuiSlider-thumb": {
                display: "none",
              },
              mr: 1,
            }}
            min={0}
            max={duration}
            defaultValue={0}
            value={currentTime}
            onChange={handleChange}
          />
        )}

        {time && (
          <Box sx={{ color: "#828d99", fontSize: "0.875rem" }}>
            <span>{calculateTime(currentTime)}</span>/
            <span>{calculateTime(duration)}</span>
          </Box>
        )}
      </Box>
    </>
  );
};
