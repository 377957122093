import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { initI18n } from "locales/i18n";
import { ScrollToTop } from "router/ScrollToTop";

import { App } from "./App";

import "./index.css";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

initI18n();

const element = (
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
);

root.render(element);
