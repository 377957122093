import { useContext, useMemo } from "react";

import { ExerciseResultError } from "@packages/data/creativeTasks";

import styles from "./ErrorPreivews.module.scss";
import { ErrorPreivewsItem } from "./ErrorPreivewsItem";
import { TaskContext } from "../utils/context";
import {
  getLexicalErrors,
  getGrammarErrors,
  getComments,
  isVisibleErrors,
} from "../utils/helpers";

export const ErrorPreivews = () => {
  const { errors = [], corrections = [] } = useContext(TaskContext);

  const visibleErrors = useMemo(() => {
    return isVisibleErrors(errors, corrections);
  }, [errors, corrections]);

  const lexicalErrors = getLexicalErrors(visibleErrors);
  const grammarErrors = getGrammarErrors(visibleErrors);
  const comments = getComments(visibleErrors);

  const getErrorList = (errors: ExerciseResultError[]) => {
    return errors.map((error, index) => (
      <ErrorPreivewsItem
        key={error.currentId || `${error.comment} ${index}`}
        error={error}
      />
    ));
  };

  return (
    <>
      <div className={styles["items"]}>{getErrorList(lexicalErrors)}</div>
      <div className={styles["items"]}>{getErrorList(grammarErrors)}</div>
      <div className={styles["items"]}>{getErrorList(comments)}</div>
    </>
  );
};
