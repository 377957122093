export type WordRange = {
  startPosition: number;
  endPosition: number;
};

export type WordHightlight = "error" | "comment" | "empty";

export enum UserRole {
  STUDENT = "student",
  TEACHER = "teacher",
}
