/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { CourseModel } from "../Course/CourseModel";
import { UnitModel } from "../Unit/UnitModel";

export const PassingModel = types
  .model({
    id: types.identifier,
    course: types.maybeNull(types.late(() => types.reference(CourseModel))),
    available: types.maybeNull(types.boolean),
    childrenListUpdatedAt: types.maybeNull(types.Date),
    lastBlockId: types.maybeNull(types.number),
    lastExerciseId: types.maybeNull(types.number),
    lastUnitId: types.maybeNull(types.number),
    mark: types.maybeNull(types.number),
    markPercent: types.maybeNull(types.number),
    markTestPercent: types.maybeNull(types.number),
    passedAt: types.maybeNull(types.Date),
    passingPercent: types.maybeNull(types.number),
    passingTestPercent: types.maybeNull(types.number),
    qCheckLibVersion: types.maybeNull(types.string),
    startedTimestamp: types.maybeNull(types.Date),
    state: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.Date),
    units: types.optional(
      types.array(types.late(() => types.reference(UnitModel))),
      []
    ),
    testingKind: types.maybeNull(types.string),
    cefrLevel: types.maybeNull(types.string),
    courseMark: types.maybeNull(types.number),
  })
  .views((self) => ({
    get url() {
      if (
        self?.course?.id &&
        self?.lastUnitId &&
        self?.lastBlockId &&
        self?.lastExerciseId
      ) {
        const courseId = self.course.id;
        const unitId = self.lastUnitId;
        const blockId = self.lastBlockId;
        const exerciseId = self.lastExerciseId;
        // eslint-disable-next-line max-len
        // return `${ROUTES.MY_COURSES}/${courseId}${ROUTES.EXERCISES}/${unitId}?blockId=${blockId}&exerciseId=${exerciseId}`;
      }
      return null;
    },
  }));

type PassingModelType = Instance<typeof PassingModel>;
export interface Passing extends PassingModelType {}
type PassingModelTypeSnapshotType = SnapshotOut<typeof PassingModel>;
export type PassingSnapshot = PassingModelTypeSnapshotType;
