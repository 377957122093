import { Box } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Course } from "@packages/store/models/Course/CourseModel";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

import { CardModule, Teachers } from "components/CardModule";
import { ItemsWithPagination } from "components/ItemsWithPagination";
import { dataTeachers } from "constants/constants";
import { ROUTES } from "router/constants";
import { addEmptyModule } from "utils/basic";

import { MyModulesContainer, ModuleContainer } from "./styledComponents";

export interface ListModulesProps {
  isBottom?: boolean;
  isProgress?: boolean;
  countPage: number;
  courses: Course[];
  groups?: LearningGroup[];
  continueUrl?: string;
}

export const ListModules = observer(
  ({
    isBottom = false,
    countPage,
    courses,
    groups,
  }: ListModulesProps): JSX.Element => {
    const { t } = useTranslation();

    const getTeachers = (module: Course): Teachers[] => {
      let teachers: Teachers[] = [];
      groups?.forEach((group) => {
        const classGroup = group.classGroups.find(
          (item) => String(item?.course?.id) === module.id
        );

        if (classGroup) {
          teachers = group?.uniqueTeachers?.map((teacher) => ({
            name: `${teacher.fullName}`,
            post: "Teacher",
          }));
        }
      });

      return teachers;
    };

    const getSubtitle = (module: Course) => {
      if (module?.passing?.testingKind === "entrance") {
        return t("Module:CurrentLevel", {
          level: module?.passing?.cefrLevel,
        });
      }

      return `${module?.knowledgeLevel?.name || ""} • ${
        module?.knowledgeLevel?.code || ""
      } ${
        module?.finishDate
          ? t("CardModule:ends", {
              date: format(module?.finishDate, "dd MMMM yyyy"),
            })
          : ""
      }`;
    };

    const itemsCardModule = addEmptyModule(courses)?.map((module) => {
      return (
        <ModuleContainer key={module.id}>
          <CardModule
            module={{
              title: module.name ?? "",
              subTitle: getSubtitle(module),
              url: `${ROUTES.MY_COURSES}/${module.id}`,
              id: module.id,
            }}
            dueDate={module.finishDate}
            dataTeachers={dataTeachers}
            resultProgress={module.resultProgress}
            totalProgress={module.totalProgress}
            picture={module.picture}
            isHide={Boolean(module.isHide)}
            isBottom={isBottom}
            isProgress={module?.lessons?.length > 0}
            unitsProgress={module.classesTimeProgress}
            classesProgress={module.classesProgress}
            teachers={getTeachers(module)}
            continueUrl={module?.passing?.url}
          />
        </ModuleContainer>
      );
    });

    return (
      <Box mb="2.5rem">
        <ItemsWithPagination
          items={itemsCardModule}
          countPage={countPage}
          ContainerItems={MyModulesContainer}
        />
      </Box>
    );
  }
);
