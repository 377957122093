import { ReactNode, useCallback, useContext } from "react";

import { ErrorSelectionContext } from "./context";

interface ErrorSelectionWordProps {
  index: number;
  children?: ReactNode;
}

export const ErrorSelectionWord = (props: ErrorSelectionWordProps) => {
  const { index, children } = props;

  const { onSelectStart, onSelectEnd } = useContext(ErrorSelectionContext);

  const handleSelectStart = useCallback(() => {
    onSelectStart?.(index);
  }, [onSelectStart, index]);

  const handleSelectEnd = useCallback(() => {
    onSelectEnd?.(index);
  }, [onSelectEnd, index]);

  return (
    <span onMouseDown={handleSelectStart} onMouseUp={handleSelectEnd}>
      {children}
    </span>
  );
};
