import { ReactNode, useCallback, useContext } from "react";

import { EMPTY_WORD_INDEX } from "../utils/constants";
import { HoverContext } from "../utils/context";

interface HoverableWordProps {
  index: number;
  children?: ReactNode;
  className?: string;
}

export const HoverableWord = (props: HoverableWordProps) => {
  const { index, children, className } = props;
  const { setHoveredError, setHoveredWordIndex } = useContext(HoverContext);

  const handleWordHover = useCallback(() => {
    setHoveredWordIndex?.(index);
  }, [index, setHoveredWordIndex]);

  const clearWordHover = useCallback(() => {
    setHoveredWordIndex?.(EMPTY_WORD_INDEX);
    setHoveredError?.(undefined);
  }, [setHoveredWordIndex]);

  return (
    <span
      onMouseEnter={handleWordHover}
      onMouseLeave={clearWordHover}
      className={className}
    >
      {children}
    </span>
  );
};
