import { Course } from "@packages/store/models/Course/CourseModel";

export const filterByName =
  (search: string) =>
  ({ name }: Course) => {
    return name?.toLocaleLowerCase().includes(search.toLocaleLowerCase());
  };

export const filterByLevel =
  (level: string) =>
  ({ knowledgeLevel }: Course) => {
    return !level || knowledgeLevel?.code === level;
  };
