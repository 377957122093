import cn from "classnames";

import { ExerciseErrorType } from "@packages/data/creativeTasks";

import styles from "./ErrorCreateMenu.module.scss";
import { Icon, IconType } from "../../Icon";

interface ErrorCreateMenuItemProps {
  text: string;
  type: ExerciseErrorType;
  isEditing?: boolean;
  onClick?: () => void;
}

const { COMMENT } = ExerciseErrorType;

export const ErrorCreateMenuItem = (props: ErrorCreateMenuItemProps) => {
  const { text, type, isEditing, onClick } = props;

  const contentClasses = cn(styles.item, {
    [styles.error]: type !== COMMENT,
    [styles.comment]: type === COMMENT,
  });

  const icon: IconType = isEditing ? "edit" : "add";

  return (
    <div className={contentClasses} onClick={onClick}>
      <span>{text}</span>
      <Icon icon={icon} />
    </div>
  );
};
