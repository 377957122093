const pt = {
  Exercise: {
    Units: "Módulos",
    Blocks: "Blocos",
    Exercises: "Exercícios",
    Unit: "Módulo",
    Block: "Bloco",
    Exercise: "Exercício",
    Script: "Roteiro do vídeo",
    HideScript: "Ocultar roteiro",
    ShowScript: "Mostrar roteiro",
    LanguageSamples: "Amostras de linguagem",
    Extras: "Materiais extras",
    ToolReload: "Recarregar",
    ToolTranslate: "Traduzir",
    TranslateHelper:
      "Selecione o texto dentro do exercício para traduzir e trabalhar com o dicionário",
    ToolCheck: "Verificar",
    ToolNext: "Avançar",
    IsCheckedWarning: "Exercício já verificado",
    NotFilledWarning: "Você não respondeu a todas as perguntas",
    Translate: "Tradução",
    Vocabulary: "Vocabulário",
    NoTranslation: "Sem tradução",
    AddToDictionary: "Adicionar ao dicionário",
    SelectedTranslationsWarning: "Selecione 1-3 traduções",
    TranslateWordFailed: "Falha ao traduzir a palavra",
    AddWordsFailed: "Falha ao adicionar palavras",
    AddWordsSuccess: "Palavras adicionadas com sucesso",
    AddedBefore: "Adicionado anteriormente",
    EnterText: "Digite o texto",
    Characters: "Caracteres",
    Words: "Palavras",
    TextSize: "Tamanho do seu texto:",
    WordsAtMinimum: "Palavras no mínimo",
    LearnWords: "Aprender palavras",
    Ok: "Ok",
    Expand: "Expandir",
    Collapse: "Recolher",
    Close: "Fechar",
    ResizeTooltip: "Expanda para ler",
    ResetChangesTooltip: "Redefinir todas as alterações",
    NoChange: "Sem alterações",
    CorrectAnswer: "Resposta correta",
    DevTooltip:
      "Este tipo de exercício está em desenvolvimento, use o link para o sistema antigo",
    FollowLink: "Siga o link",
    CreativeExercisePassed: "Seu exercício foi enviado para verificação",
    CreativeExerciseVerified: "Seu trabalho foi verificado. Clique",
    CreativeExerciseVerifiedLink: "aqui",
    DialogExerciseConfirmAnswer: "Confirmar",
    DialogExerciseResetAnswer: "Regravar",
    DialogExerciseSelectAnswer: "Este",
    DialogExerciseHelperLegend: "Como realizar o exercício",
    DialogExerciseFinishHelperLegend: "Parabéns!",
    DialogExerciseFirstHelper:
      "Mentalmente, escolha sua resposta na lista abaixo, clique no microfone e pronuncie-a.",
    DialogExerciseSecondHelper:
      "Ótimo, o sistema reconheceu sua fala! Confirme sua resposta ou regrave-a.",
    DialogExerciseThirdHelper:
      "Suas tentativas de gravação acabaram, selecione uma resposta manualmente.",
    DialogExerciseFinishHelper:
      "Você concluiu o exercício. Você pode ouvir o diálogo ou ir para o próximo exercício clicando em Verificar.",
    DialogExerciseRecognitionError:
      "Infelizmente, o sistema não reconheceu sua resposta. Tente novamente.",
    AudioRecorderText: "Clique para gravar ({{count}}) seg",
    RadioLineHelperTextLeft:
      "Nem todas as respostas corretas foram marcadas. Encontre mais",
    RadioLineHelperTextRight: "respostas corretas.",
    NextDisabledWarning: "Verifique o exercício",
    BrokenError:
      "Este exercício está em desenvolvimento, realize-o no sistema antigo.",
    BrokenErrorLink: "Link",
  },
  ResultsModals: {
    UnitTitle: "Resultados do módulo {{name}}",
    BlockTitle: "Resultados do bloco {{name}}",
    Passed: "Corretas: {{count}}",
    Wrong: "Erros: {{count}}",
    Skipped: "Puladas: {{count}}",
    Blocks: "Blocos",
    Block: "Bloco {{order}}: {{name}}",
    Exercises: "Exercícios",
    Exercise: "Exercício {{order}}: {{name}}",
    Repeat: "Repetir",
    Continue: "Continuar",
    DontShowAgain:
      "Não mostrar novamente (pode ser reativado nas configurações do perfil)",

    TestUnitTitle: "Parabéns! Você concluiu a etapa {{order}} do teste",
    TestUnitResultTitle: "Seu resultado -",
    TestUnit: "Módulo {{order}}. {{name}}",
    TestUnitTotalProgress: "Progresso geral",
    TestUnitResults: "Resultados",
    TestUnitNext: "Iniciar próxima etapa",
    TestUnitScoredPoints_zero: "{{count}}/{{total}} pontos",
    TestUnitScoredPoints_one: "{{count}}/{{total}} ponto",
    TestUnitScoredPoints_few: "{{count}}/{{total}} pontos",
    TestUnitScoredPoints_many: "{{count}}/{{total}} pontos",

    TestCourseTitle: "Parabéns! Você concluiu o teste",
    TestCourseRecomendedLevel: "O nível recomendado para você é -",
    TestCourseTotalProgress: "Progresso geral",
    TestCourseResultProgress: "Resultados",
    TestCourseShowResults: "Ver resultados",
    TestCourseShowHourse: "Quant. h/a",
    TestCourseHint:
      "Foco no desenvolvimento de todas as habilidades linguísticas (fala, audição, leitura e escrita)",
    TestCourseA1Long:
      "Entendo o significado básico de conversas. Posso descrever meus amigos e família.",
    TestCourseA2Long:
      "Entendo frases simples e posso conversar sobre temas cotidianos.",
    TestCourseB1Long:
      "Consigo me comunicar na maioria das situações usando todos os tempos verbais.",
    TestCourseB2Long:
      "Posso defender meu ponto de vista, negociar e manter correspondência.",
    TestCourseC1Long: "Leio literatura livremente e sei fazer apresentações.",
    TestCourseC2Long:
      "Comunico-me sobre qualquer tema em um nível próximo ao de um nativo.",
    TestCourseA1Short: "iniciante",
    TestCourseA2Short: "básico",
    TestCourseB1Short: "intermediário",
    TestCourseB2Short: "intermediário superior",
    TestCourseC1Short: "avançado",
    TestCourseC2Short: "proficiente",
  },
  TestingCourseResult: {
    ModalTitle: "Resultados do teste",
    Confirm: "Ver resultados",
    ModalBodyEntrance:
      "Prezado(a) {{fullName}}, o nível de curso recomendado para você é: {{cefrLevel}}.",
    ModalBodyFinal:
      'Prezado(a) {{fullName}}, você concluiu o teste com $t(TestingCourseResult:courseMark, {"count": {{courseMark}} }) de {{maxMark}}.\nSeu resultado - {{percentage}}%.',
    ModalBodyDefault:
      "Prezado(a) {{fullName}}, você concluiu o teste com sucesso.",
    courseMark_zero: "{{count}} pontos",
    courseMark_one: "{{count}} ponto",
    courseMark_few: "{{count}} pontos",
    courseMark_many: "{{count}} pontos",
  },
};

export default pt;
