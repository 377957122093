import { useMemo } from "react";

import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { ExerciseResultExerciseType } from "@packages/data/creativeTasks";

import styles from "./Answer.module.scss";
import { AnswerTextStudent } from "./AnswerTextStudent";
import { AnswerTextTeacher } from "./AnswerTextTeacher";
import { useUser } from "../../../hooks";
import { Audio } from "../../Audio";
import { Text } from "../../Text";

interface AnswerProps {
  type: ExerciseResultExerciseType;
  text: string;
}

export const Answer = observer((props: AnswerProps) => {
  const { type, text } = props;

  const { t } = useTranslation();

  const isWritting = type === "writing";

  const { isTeacher } = useUser();

  const allText = text.trim();

  const allWords = allText.split(" ").filter(Boolean);

  const allSentenses = allText.split(".").filter(Boolean);

  const AnswerContent = useMemo(() => {
    if (isWritting) {
      return isTeacher ? (
        <AnswerTextTeacher text={text} />
      ) : (
        <AnswerTextStudent text={text} />
      );
    }

    return <Audio src={text} />;
  }, [text, isTeacher, isWritting]);

  return (
    <>
      <div className={styles["top"]}>
        <Text variant="text-2-regular" color="base-d">
          {t("CreativeTasks:AnswerHeaderStatistics", {
            questions: 1,
            symbols: isWritting ? allText.length : 0,
            words: isWritting ? allWords.length : 0,
            sentenses: isWritting ? allSentenses.length : 0,
          })}
        </Text>
      </div>
      {AnswerContent}
    </>
  );
});
