import {
  CourseResponse,
  transformCourseResponse,
  CoursesResponse,
} from "./types";
import { api } from "../api";

export type GetCoursesParams = {
  level?: string;
};

export const getCourses = (params?: GetCoursesParams) => {
  return api
    .get<CoursesResponse>("/api/v2/courses", { params })
    .then((res) => res.data);
};

export const getCourse = (courseId: string) => {
  return api
    .get<CourseResponse>(`/api/v3/courses/${courseId}`)
    .then((res) => transformCourseResponse(res.data));
};
