import { observer } from "mobx-react-lite";

import { RegisterForm } from "./components";
import { Intro, LinkDrawer } from "../Login/components";
import { Container } from "../Login/styledComponents";

export const Register = observer((): JSX.Element => {
  return (
    <>
      <Container>
        <Intro />
        <RegisterForm />
        <LinkDrawer />
      </Container>
    </>
  );
});
