import { useState, useEffect, useCallback } from "react";

import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useSynthesizeSpeech, useTranslateText } from "@packages/data/words";
import { useStores } from "@packages/store/models";
import { WordTranslation } from "@packages/store/services/Api";

import { IconWrapper } from "./styledComponents/IconWrapper";
import { StyledPopover } from "./styledComponents/StyledPopover";
import { Tab } from "./styledComponents/Tab";
import { Tabs } from "./styledComponents/Tabs";
import { Translation } from "./Translation";
import { Vocabulary } from "./Vocabulary";
import { Icon } from "../../components/Icon";
import { Loading } from "../../components/Loading";
import { TabPanel } from "../../components/TabPanel";
import { tablet } from "../../constants/styles";
import { useFetch } from "../../hooks/useFetch";
import { usePopover } from "../../hooks/usePopover";
import { useTranslateMode } from "../../hooks/useTranslateMode";

interface AnchorPosition {
  top: number;
  left: number;
}

type Tab = "translation" | "vocabulary";

interface TranslationMenuProps {
  synthesizeSpeechBaseUrl: string;
  languageCode: string;
  showVocabulary: boolean;
}

export const TranslationMenu = observer(
  (props: TranslationMenuProps): JSX.Element | null => {
    const { synthesizeSpeechBaseUrl, languageCode, showVocabulary } = props;

    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const isTouchScreen = useMediaQuery(theme.breakpoints.down(tablet));

    const [currentTab, setCurrentTab] = useState<Tab>("translation");
    const [selectedText, setSelectedText] = useState("");
    const [selectedWord, setSelectedWord] = useState("");
    const [textTranslation, setTextTranslation] = useState("");
    const [wordTranslation, setWordTranslation] =
      useState<WordTranslation | null>(null);
    const [anchorPosition, setAnchorPosition] = useState<AnchorPosition>({
      top: 0,
      left: 0,
    });

    const { open, handleOpen, handleClose } = usePopover({
      onOpen: () => setCurrentTab("translation"),
    });

    const { api } = useStores();

    const {
      fetch: translateWord,
      loading: translateWordLoading,
      error: translateWordError,
    } = useFetch(
      (word: string) =>
        api.getWordTranslation(word, `${languageCode}-${i18n.language}`),
      (data: WordTranslation) => setWordTranslation(data)
    );

    const {
      mutate: translateTextMutation,
      isPending: translateTextLoading,
      isError: translateTextError,
    } = useTranslateText();

    const { mutate: synthesizeSpeech, isPending: isSpeechPending } =
      useSynthesizeSpeech();

    const { translateMode } = useTranslateMode();

    const translationLoading = translateTextLoading || translateWordLoading;
    const translationError = translateTextError || translateWordError;

    const translateText = useCallback(
      (text: string) => {
        return translateTextMutation(
          {
            baseUrl: synthesizeSpeechBaseUrl,
            text,
            sourceLanguageCode: languageCode as any,
            targetLanguageCode: i18n.language as any,
          },
          {
            onSuccess: (translation) => setTextTranslation(translation ?? ""),
          }
        );
      },
      [
        i18n.language,
        languageCode,
        synthesizeSpeechBaseUrl,
        translateTextMutation,
      ]
    );

    const handleTabChange = (tab: Tab) => () => setCurrentTab(tab);

    const handleSoundPlay = () => {
      if (!selectedText) return;

      synthesizeSpeech(
        {
          baseUrl: synthesizeSpeechBaseUrl,
          text: selectedText,
          lang: languageCode as any,
        },
        {
          onSuccess: (audioUrl) => new Audio(audioUrl).play(),
        }
      );
    };

    const renderTabPanel = (): JSX.Element | null => {
      if (translationError) {
        return (
          <Typography variant="regularText">
            {t("Exercise:TranslateWordFailed")}
          </Typography>
        );
      }

      const noTranslation =
        !wordTranslation ||
        wordTranslation.dictionaryWord.translations.length === 0;

      if (noTranslation && !textTranslation) {
        return (
          <Typography variant="regularText">
            {t("Exercise:NoTranslation")}
          </Typography>
        );
      }

      const { definitions = [], transcription = "" } = wordTranslation ?? {};
      let { translations = [] } = wordTranslation?.dictionaryWord ?? {};

      // Если текст больше одного слова то нужно показывать только этот текст
      if (selectedText !== selectedWord || (noTranslation && textTranslation)) {
        translations = [textTranslation];
      }

      return (
        <>
          <TabPanel remount value={currentTab} tabValue="translation">
            <Translation translations={translations} />
          </TabPanel>

          <TabPanel remount value={currentTab} tabValue="vocabulary">
            <Vocabulary
              word={selectedWord}
              definitions={definitions}
              transcription={transcription}
              languageFrom={languageCode}
              languageTo={i18n.language}
              onClose={handleClose}
            />
          </TabPanel>
        </>
      );
    };

    useEffect(() => {
      const handleSelectionChange = () => {
        const selection = window.getSelection();

        if (!selection) return;

        const newSelectedText = selection
          .toString()
          .replace(/ {2}/g, " ")
          .trim();

        if (!newSelectedText) return;

        const firstWord = newSelectedText
          .replace(/[,.;]/g, "")
          .trim()
          .split(" ")
          .filter((word) => word !== "-")[0];

        if (!firstWord) return;

        const { top, left, height } = selection
          .getRangeAt(0)
          .getBoundingClientRect();

        setSelectedText(newSelectedText);
        setSelectedWord(firstWord);
        translateText(newSelectedText);
        // Переводы первого слова используются только для показа в словаре
        if (showVocabulary) {
          translateWord(firstWord);
        }
        setAnchorPosition({ top: top + height, left });

        selection.removeAllRanges();

        if (isTouchScreen) {
          setTimeout(handleOpen, 100);
        } else {
          handleOpen();
        }
      };

      if (translateMode) {
        document.addEventListener("pointerup", handleSelectionChange);
        document.addEventListener("pointercancel", handleSelectionChange);
        document.addEventListener("dblclick", handleSelectionChange);
      }

      return () => {
        document.removeEventListener("pointerup", handleSelectionChange);
        document.removeEventListener("pointercancel", handleSelectionChange);
        document.removeEventListener("dblclick", handleSelectionChange);
      };
    }, [
      handleOpen,
      isTouchScreen,
      showVocabulary,
      translateMode,
      translateText,
      translateWord,
    ]);

    return (
      <StyledPopover
        open={open}
        elevation={4}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={handleClose}
      >
        {showVocabulary && (
          <Tabs>
            <Tab
              active={currentTab === "translation"}
              onClick={handleTabChange("translation")}
            >
              <Icon type="bookMark" sizeSquareIcon={16} />

              <Typography variant="regularText" color="currentColor">
                {t("Exercise:Translate")}
              </Typography>
            </Tab>

            <Tab
              active={currentTab === "vocabulary"}
              onClick={handleTabChange("vocabulary")}
            >
              <Icon type="words" sizeSquareIcon={16} />

              <Typography variant="regularText" color="currentColor">
                {t("Exercise:Vocabulary")}
              </Typography>
            </Tab>
          </Tabs>
        )}

        <Stack direction="row" gap={3}>
          <Loading
            loading={isSpeechPending}
            wrapperStyles={{
              p: 0,
              flexGrow: 0,
            }}
            size={16}
          >
            <IconWrapper sx={{ pt: "0.51px" }}>
              <Icon
                type="bxVolumeFull"
                sizeSquareIcon={16}
                onClick={handleSoundPlay}
              />
            </IconWrapper>
          </Loading>

          <Typography variant="h3">{selectedText}</Typography>
        </Stack>

        <Loading loading={translationLoading}>{renderTabPanel()}</Loading>
      </StyledPopover>
    );
  }
);
