import { ReactNode } from "react";

import cn from "classnames";

import styles from "./Answer.module.scss";
import { Text, TextColor } from "../../Text";
import { WordHightlight } from "../utils/types";

interface AnswerWordProps {
  children: ReactNode;
  color?: TextColor;
  highlight?: WordHightlight;
  hovered?: boolean;
}

export const AnswerWord = function AnswerWord(props: AnswerWordProps) {
  const { children, color, highlight = "empty", hovered } = props;

  const wordClasses = cn(
    styles["word"],
    {
      [styles.hovered]: hovered,
    },
    styles[highlight]
  );

  return (
    <Text
      containerClassName={wordClasses}
      variant="text-1-regular"
      color={color ?? "base-d"}
    >
      {children}
    </Text>
  );
};
