import cn from "classnames";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { ExerciseResult } from "@packages/data/creativeTasks";
import { useStores } from "@packages/store";

import styles from "./Task.module.scss";
import { getButtonType, getTypeIcon } from "./utils/helpers";
import { ButtonType } from "./utils/types";
import { formatTime } from "../../../utils/helpers";
import { Button } from "../../Button";
import { Icon } from "../../Icon";
import { Text } from "../../Text";

interface TaskProps {
  containerClassName?: string;
  data: ExerciseResult;
  onButtonClick?: (_: string) => void;
}

export const Task = observer((props: TaskProps) => {
  const { containerClassName, data, onButtonClick } = props;

  const { t } = useTranslation();

  const { auth } = useStores();

  const { user } = auth;

  const { isTeacher } = user ?? {};

  const username = t(`CreativeTasks:${isTeacher ? "Student" : "Teacher"}Name`, {
    name: data.userName,
  });

  const handleButtonClick = () => {
    if (["new_check", "processing"].includes(data.status) && !isTeacher) {
      return;
    }

    onButtonClick?.(data.id);
  };

  const buttonType = getButtonType(data.hasErrors, data.status);

  const buttonClasses = cn(styles.btn, styles[buttonType], {
    [styles["for-teacher"]]: isTeacher,
  });

  const buttonTexts: Record<ButtonType, string> = {
    checking: t("CreativeTasks:Checking"),
    error: isTeacher
      ? t("CreativeTasks:FixTeacher")
      : t("CreativeTasks:FixStudent"),
    success: t("CreativeTasks:Done"),
  };

  const containerClasses = cn(styles.container, containerClassName);

  const typeIcon = getTypeIcon(data.exerciseType);

  const iconClasses = cn(styles.icon, {
    [styles.checking]: buttonType === "checking",
  });

  const { unit, part, exercise } = data;

  const buttonText = buttonTexts[buttonType];

  return (
    <div className={containerClasses}>
      <div className={styles.top}>
        <Text variant="text-3-regular">
          {t("CreativeTasks:DoneTime", {
            time: formatTime(data.createdAt),
          })}
        </Text>
        {data.checkedAt && (
          <Text variant="text-3-regular" color="check-error">
            {t("CreativeTasks:CheckingTime", {
              time: formatTime(data.checkedAt),
            })}
          </Text>
        )}
        <Text variant="text-3-regular">{username}</Text>
      </div>
      <div className={styles.bottom}>
        <div className={styles["course-info-container"]}>
          <Icon containerClassName={iconClasses} icon={typeIcon} />
          <div className={styles["course-info"]}>
            <Text variant="text-2-semibold" color="main-color">
              {data.name}
            </Text>
            <Text variant="text-3-regular">
              {t("CreativeTasks:TaskSubtitle", {
                unit,
                part,
                exercise,
              })}
            </Text>
          </div>
        </div>
        <Button containerClassName={buttonClasses} onClick={handleButtonClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
});
