import { createContext } from "react";

export interface ErrorSelectionContextProps {
  onSelectStart?: (_: number) => void;
  onSelectEnd?: (_: number) => void;
}

export const ErrorSelectionContext = createContext<ErrorSelectionContextProps>(
  {}
);
