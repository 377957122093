const pt = {
  Groups: {
    title: "Grupos",
  },
  GroupList: {
    group: "Grupo",
    dates: "Período das aulas",
    report: "Relatórios das aulas",
    statistics: "Estatísticas da plataforma",
    progress: "Progresso da plataforma",
    lessonsCount: "Aulas realizadas",
    total: "Total h/a",
    finished: "Realizado h/a",
    left: "Restante h/a",
    period: "Período",
    teachers: "Professores",
    attendance: "Frequência",
  },
  ModalGroupReport: {
    Download: "Baixar",
    Teacher: "Professor",
    Course: "Curso",
    Schedule: "Cronograma",
    Hours: "h/a",
    TotalHours: "Total h/a",
    CompletedHours: "Realizado h/a",
    LeftHours: "Restante h/a",
    Compensation: "Compensação",
    Students: "Estudantes",
    Duration: "Duração",
  },
  GroupFilter: {
    ActiveGroups: "Grupos ativos",
    ActiveGroupsNo: "Não",
    ActiveGroupsYes: "Sim",
    SelectLevel: "Selecionar nível",
    SearchStudent: "Buscar estudante",
    SearchGroup: "Buscar grupo",
    SelectModule: "Selecionar módulo",
    AllModules: "Todos os módulos",
    AllCompanies: "Todas as empresas",
    SelectCompany: "Selecionar empresa",
    StartDate: "Data de início",
    EndDate: "Data de término",
    WithLessonsWithoutTeachers: "Sem professor",
    WithLessonsOnlyTechnicalCancellationBefore: "Cancelamentos DS",
  },
  GroupDownload: {
    Download: "Exportar",
    Groups: "Grupos",
    WithoutPrice: "Grupos sem preço",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Digite o nome do grupo",
    SelectGroup: "Selecionar grupo",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "Você não possui módulos atribuídos.",
    SecondTextDefault: "Contate seu coordenador pedagógico.",
    FirstTextDistributor: "Nenhum dado para exibir.",
    SecondTextDistributor: "Tente adicionar alguns dados.",
    FirstTextClasses: "Nenhum dado para exibir.",
    SecondTextClasses: "Altere os filtros ou contate o suporte.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Nenhum professor encontrado",
    SelectTeacher: "Selecionar professor",
  },
  ShortWeekDay: {
    "0": "DOM",
    "1": "SEG",
    "2": "TER",
    "3": "QUA",
    "4": "QUI",
    "5": "SEX",
    "6": "SÁB",
  },
  LessonStatuses: {
    completed: "Realizado",
    planned: "Agendado",
    earlyCanceledByCustomer: "Cancelamento antecipado pelo aluno",
    earlyCanceledBySchool: "Cancelamento antecipado pelo professor",
    lateCanceledByCustomer: "Cancelamento tardio pelo aluno",
    lateCanceledBySchool: "Cancelamento tardio pelo professor",
    notPerformedToPay: "A pagar",
  },
  DatePicker: {
    Today: "Hoje",
    Clear: "Limpar",
    MaskPlaceholder: "DD/MM/AAAA",
  },
};

export default pt;
