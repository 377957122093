import { ExerciseResultError } from "@packages/data/creativeTasks";

export function getUniqueErrors(
  errors: ExerciseResultError[]
): ExerciseResultError[] {
  const duplicates = new Set();
  return errors
    .filter((error) => {
      const { startPosition, endPosition, errorTypeId } = error;
      const id = `${startPosition}-${endPosition}-${errorTypeId}`;
      if (duplicates.has(id)) {
        return false;
      }
      duplicates.add(id);
      return error;
    })
    .filter(Boolean);
}
