import { ReactNode } from "react";

import styles from "./Loading.module.scss";

interface LoadingProps {
  loading?: boolean;
  children?: ReactNode;
}

export const Loading = function Loading(props: LoadingProps) {
  const { loading, children } = props;

  if (!loading) {
    return <>{children}</>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.loading} />
    </div>
  );
};
