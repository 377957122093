import { useStores } from "@packages/store";
import { User } from "@packages/store/models/User/User";

export function useUser(): User {
  const { auth } = useStores();

  const { user } = auth;

  return (user ?? {}) as User;
}
