import { ExerciseResultError } from "@packages/data/creativeTasks";

import { getWordHoveredList } from "./getWordHoveredList";
import { getWordIndexesFromRange } from "./getWordIndexesFromRange";

export function getHoveredWordIndexes(
  wordIndex: number,
  errors: ExerciseResultError[],
  hoveredError?: ExerciseResultError
): number[] {
  const errorsWithComments = errors;

  const hoveredList = [
    ...getWordHoveredList(errorsWithComments, wordIndex),
    ...(hoveredError ? getWordIndexesFromRange(hoveredError) : []),
  ];

  const list = hoveredList.reduce((result: number[], index: number) => {
    return [...result, ...getWordHoveredList(errorsWithComments, index)];
  }, []);

  return [...new Set(list)];
}
