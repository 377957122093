import {
  GetCreativeTasksParams,
  GetCreativeTaskParams,
  UpdateCreativeTaskParams,
  CreativeTasksResponse,
  CreativeTaskResponse,
  transformCreativeTasksResponse,
  transformCreativeTaskResponse,
  ExerciseResultError,
  ExerciseResultCorrection,
  ExerciseErrorType,
} from "./index";
import { api } from "../api";

export const getCreativeTasks = (params: GetCreativeTasksParams) => {
  const { role } = params;

  const url =
    role === "teacher"
      ? "/api/v2/exercise_checking_results/index_teacher"
      : `/api/v3/exercises/results`;

  return api
    .get<CreativeTasksResponse>(url)
    .then((res) => transformCreativeTasksResponse(res.data));
};

export const getCreativeTask = (params: GetCreativeTaskParams) => {
  const { id, role } = params;

  const action = role === "teacher" ? "show_teacher" : "show_student";
  const url = `/api/v2/exercise_checking_results/${action}/${id}`;

  return api
    .get<CreativeTaskResponse>(url)
    .then((res) => transformCreativeTaskResponse(res.data));
};

export const updateCreativeTask = (params: UpdateCreativeTaskParams) => {
  const getUpdateParams = () => {
    const {
      id,
      comment,
      initialErrors,
      errors,
      initialCorrections,
      corrections,
      role,
      isSubmit,
    } = params;
    return {
      id,
      comment,
      role,
      isSubmit,
      ...(role === "teacher"
        ? {
            errors,
            removedErrors: initialErrors?.filter((item) => {
              return !errors?.find(
                ({ currentId }) => currentId === item.currentId
              );
            }),
          }
        : {}),
      ...(role !== "teacher"
        ? {
            corrections,
            removedCorrections: initialCorrections?.filter((item) => {
              return !corrections?.find(
                ({ currentId }) => currentId === item.currentId
              );
            }),
          }
        : {}),
    };
  };

  const {
    id,
    role,
    isSubmit,
    comment,
    errors,
    corrections,
    removedCorrections,
    removedErrors,
  } = getUpdateParams();

  // Эти функции используются только тут из-за странного метода передачи параметров в запросе
  const objectToUrlParams = (
    obj: Record<string, any>,
    parentKey?: string
  ): string => {
    const params = [];

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        const paramName = parentKey ? `${parentKey}[${key}]` : key;

        if (typeof value === "object" && value !== null) {
          params.push(objectToUrlParams(value, paramName));
        } else {
          params.push(`${paramName}=${value}`);
        }
      }
    }

    return params.join("&");
  };

  const objectToEncodedURIParams = (data: Record<string, any>) => {
    return encodeURI(objectToUrlParams(data));
  };

  const filterExerciseResultErrorsByTypes = (
    errors: ExerciseResultError[],
    types: ExerciseErrorType[]
  ) => {
    return errors.filter(({ errorTypeId }) => types.includes(errorTypeId));
  };

  const getUrl = () => {
    const action =
      role === "teacher"
        ? "exercise_checking_results"
        : "exercise_checking_results_view";

    const submitAction =
      role === "teacher" ? "update_with_check" : "update_with_correct";

    return `/${action}/${id}${isSubmit ? `/${submitAction}` : ""}`;
  };

  const getErrors = (errors: ExerciseResultError[]): any => {
    return errors.reduce(
      (result, error, index) => ({
        ...result,
        [index]: {
          ...(error.currentId ? { id: error.currentId } : {}),
          start_position: error.startPosition,
          end_position: error.endPosition,
          comment: error.comment,
          exercises_error_type_id: error.errorTypeId,
          question_id: 1,
          ...(removedErrors?.some((item) => item === error)
            ? {
                _destroy: 1,
              }
            : {}),
        },
      }),
      {}
    );
  };

  const getCorrections = (corrections: ExerciseResultCorrection[]) => {
    return corrections.reduce(
      (result, error, index) => ({
        ...result,
        [index]: {
          ...(error.currentId ? { id: error.currentId } : {}),
          start_position: error.startPosition,
          end_position: error.endPosition,
          text: error.text,
          question_id: 1,
          ...(removedCorrections?.some((item) => item === error)
            ? {
                _destroy: 1,
              }
            : {}),
        },
      }),
      {}
    );
  };

  const allErrors = [...(errors ?? []), ...(removedErrors ?? [])];
  const allCorrections = [
    ...(corrections ?? []),
    ...(removedCorrections ?? []),
  ];

  const comments = filterExerciseResultErrorsByTypes(allErrors, [
    ExerciseErrorType.COMMENT,
  ]);

  const data = objectToEncodedURIParams({
    exercise_checking_result: {
      comment,
      ...(errors
        ? {
            exercise_checking_result_errors_attributes: getErrors(
              filterExerciseResultErrorsByTypes(allErrors, [
                ExerciseErrorType.GRAMMAR,
                ExerciseErrorType.LEXICAL,
              ])
            ),
            ...(comments.length
              ? {
                  exercise_checking_result_comments_attributes:
                    getErrors(comments),
                }
              : {}),
          }
        : {}),
      ...(corrections
        ? {
            exercise_checking_result_corrections_attributes:
              getCorrections(allCorrections),
          }
        : {}),
    },
    allow_correction: 1,
  });

  return api
    .patch<CreativeTaskResponse>(getUrl(), data, {
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "x-requested-with": "XMLHttpRequest",
      },
    })
    .then((res) => res.data);
};
