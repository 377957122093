import {
  ExerciseResultStatus,
  ExerciseResultExerciseType,
} from "@packages/data/creativeTasks";

import { ButtonType } from "./types";
import { IconType } from "../../../Icon";

export function getButtonType(
  hasErrors: boolean,
  status: ExerciseResultStatus
): ButtonType {
  if (hasErrors) {
    return "error";
  }

  if (status === "checked") {
    return "success";
  }

  return "checking";
}

export function getTypeIcon(type: ExerciseResultExerciseType): IconType {
  const icons: Record<ExerciseResultExerciseType, IconType> = {
    speaking: "task-micro",
    writing: "task-book",
  };

  return icons[type];
}
