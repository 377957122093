import { Instance, SnapshotOut, types } from "mobx-state-tree";

export enum ProfileCountryISO {
  RU = "ru",
}

export const ProfileCountryModel = types.model("ProfileCountry", {
  id: types.identifier,
  iso: types.maybeNull(types.string),
});

type ProfileCountryModelType = Instance<typeof ProfileCountryModel>;
type ProfileCountryModelTypeSnapshotType = SnapshotOut<
  typeof ProfileCountryModel
>;

export type ProfileCountry = ProfileCountryModelType;
export type ProfileCountrySnapshot = ProfileCountryModelTypeSnapshotType;
