import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Block } from "@packages/store/models/Block/Block";
import { Course } from "@packages/store/models/Course/CourseModel";
import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { Unit } from "@packages/store/models/Unit/UnitModel";

import { getItemStatus } from "../../helpers/getItemStatus";
import { BlockView } from "../BlockView";
import { ExerciseView } from "../ExerciseView";
import { NavigationPopover } from "../NavigationPopover";
import { StyledPaper } from "../StyledPaper";
import { UnitView } from "../UnitView";

interface NavigationPanelProps {
  currentCourse: Course | undefined;
  currentUnit: Unit | undefined;
  unitLoading: boolean;
  currentBlock: Block | undefined;
  blockLoading: boolean;
  currentExercise: Exercise | undefined;
  onUnitChange: (unitId: string) => () => void;
  onBlockChange: (blockId: string) => () => void;
  onExerciseChange: (exerciseId: string) => () => void;
}

export const NavigationPanel = observer(
  ({
    currentCourse,
    currentUnit,
    unitLoading,
    currentBlock,
    blockLoading,
    currentExercise,
    onUnitChange,
    onBlockChange,
    onExerciseChange,
  }: NavigationPanelProps): JSX.Element | null => {
    const { t } = useTranslation();

    return (
      <StyledPaper
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          p: 4,
        }}
      >
        <Typography variant="h3">{currentCourse?.name}</Typography>

        <Box
          sx={{
            display: "flex",
            gap: 4,
            flexWrap: "wrap",
          }}
        >
          <NavigationPopover
            disabled={currentCourse?.isForTesting}
            widgetSubTitle={t("Exercise:Units")}
            popoverTitle={t("Exercise:Unit")}
            iconType="elementaryGrey"
            currentItem={currentUnit}
            items={currentCourse?.sortedUnits}
            getItemName={(unit) => String(unit?.nameWithoutUnit)}
            renderItem={(unit, index) => (
              <UnitView
                key={unit.id}
                unit={unit}
                order={index + 1}
                status={getItemStatus(unit, currentUnit)}
                onClick={onUnitChange(unit.id)}
              />
            )}
          />

          <NavigationPopover
            disabled={currentCourse?.isForTesting}
            widgetSubTitle={t("Exercise:Blocks")}
            popoverTitle={t("Exercise:Block")}
            iconType="bxLibrary"
            currentItem={currentBlock}
            items={currentUnit?.sortedBlocks}
            getItemName={(block) => String(block?.name)}
            renderItem={(block, index) => (
              <BlockView
                key={block.id}
                block={block}
                order={index + 1}
                status={getItemStatus(block, currentBlock)}
                onClick={onBlockChange(block.id)}
              />
            )}
            loading={unitLoading}
          />

          <NavigationPopover
            disabled={currentCourse?.isForTesting}
            widgetSubTitle={t("Exercise:Exercises")}
            popoverTitle={t("Exercise:Exercise")}
            iconType="penGrey"
            currentItem={currentExercise}
            items={currentBlock?.sortedExercises}
            getItemName={(exercise) => String(exercise?.name)}
            renderItem={(exercise, index) => (
              <ExerciseView
                key={exercise.id}
                exercise={exercise}
                order={index + 1}
                status={getItemStatus(exercise, currentExercise)}
                onClick={onExerciseChange(exercise.id)}
              />
            )}
            loading={blockLoading}
          />
        </Box>
      </StyledPaper>
    );
  }
);
