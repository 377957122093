import {
  ExerciseResultCorrection,
  ExerciseResultError,
} from "@packages/data/creativeTasks";

export function isVisibleErrors(
  errors: ExerciseResultError[],
  editedWords: ExerciseResultCorrection[]
): ExerciseResultError[] {
  return errors.filter(({ startPosition, endPosition }) => {
    const isFinded = !editedWords.find((word) => {
      return (
        startPosition >= word.startPosition && endPosition <= word.endPosition
      );
    });
    return isFinded;
  });
}
