import { WordRange } from "../types";

export function getWordIndexesFromRange({
  endPosition,
  startPosition,
}: WordRange): number[] {
  return new Array(endPosition - startPosition + 1)
    .fill(0)
    .map((_, index) => startPosition + index);
}
