import {
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { UseFormReturn, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";

import { AttendanceReportsDateRangeFilter } from "./AttendanceReportsDateRangeFilter";
import { SearchFilter } from "./SearchFilter";
import { FilterContainer } from "./styledComponents";
import { Filters } from "../../AttendanceReports";
import { GroupsAutocompleteFilter } from "../../components/GroupsAutocompleteFilter";
import { getAutocompleteFieldValue } from "../../helpers";
import { ValueWithLabel } from "../../types";

export interface ReportsAttendanceFilterProps {
  methods: UseFormReturn<Filters>;
  additionalFilterVisible: boolean;
  onlyTeachersLessonsFilterVisible: boolean;
  studentFilterVisible: boolean;
  companyFilterVisible: boolean;
}

export const ReportsAttendanceFilter = observer(
  (props: ReportsAttendanceFilterProps): JSX.Element => {
    const {
      methods,
      additionalFilterVisible,
      onlyTeachersLessonsFilterVisible,
      studentFilterVisible,
      companyFilterVisible,
    } = props;

    const { control, watch } = methods;

    const { t } = useTranslation();
    const { customerCompany } = useStores();

    const companies = [
      ...customerCompany.items.map(
        (item) =>
          ({
            label: item.name,
            value: item.id,
          } as ValueWithLabel)
      ),
      {
        label: t("ReportsAttendance:AllCompanies"),
        value: "",
      },
    ];

    const sortedCompanies = companies.sort((prev, next) => {
      return prev.label > next.label ? 1 : -1;
    });

    // Почему-то полностью через контроллер не работает
    const companyId = watch("companyId");

    return (
      <FilterContainer>
        {companyFilterVisible && (
          <Controller
            name="companyId"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <Autocomplete
                  disablePortal
                  openOnFocus
                  options={sortedCompanies}
                  sx={{ minWidth: 200, marginTop: "0.5rem" }}
                  value={getAutocompleteFieldValue(sortedCompanies, companyId)}
                  onChange={(_, v) => onChange(v?.value)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={t("ReportsAttendance:SelectCompany")}
                    />
                  )}
                />
              );
            }}
          />
        )}
        <Controller
          name="groupId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <GroupsAutocompleteFilter onChange={onChange} group={value} />
          )}
        />
        {additionalFilterVisible && (
          <>
            <AttendanceReportsDateRangeFilter methods={methods} />

            {studentFilterVisible && (
              <Controller
                name="studentId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SearchFilter
                    placeholder={t("GroupFilter:SearchStudent")}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            )}

            {onlyTeachersLessonsFilterVisible && (
              <Controller
                name="onlyTeachersLessons"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={value}
                        onChange={(_, v) => onChange(v)}
                      />
                    }
                    label={
                      <Typography variant="regularText">
                        {t("ReportsAttendance:OnlyTeachersLessons")}
                      </Typography>
                    }
                  />
                )}
              />
            )}
          </>
        )}
      </FilterContainer>
    );
  }
);
