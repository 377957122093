import { createInstance } from "i18next";

import en from "./en";
import pt from "./pt";
import ru from "./ru";
import tt from "./tt";

const i18n = createInstance({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  nsSeparator: ":",
  resources: { en, ru, tt, pt },
});

i18n.init();

export default i18n;
