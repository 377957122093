import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  getCourses,
  getCourse,
  CoursesResponse,
  GetCoursesParams,
} from "./index";

export const useGetCourses = <T = CoursesResponse>(
  params?: GetCoursesParams,
  options?: Omit<
    UseQueryOptions<CoursesResponse, Error, T>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery({
    queryKey: ["courses", params],
    queryFn: () => getCourses(params),
    ...options,
  });
};

export const useGetCourse = (courseId?: string) => {
  return useQuery({
    queryKey: ["courseDetails", courseId],
    queryFn: () => getCourse(courseId ?? ""),
    enabled: !!courseId,
  });
};
