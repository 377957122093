import { useState, useRef, useEffect, useCallback } from "react";

import { CancelToken as CancelTokenGenerator } from "apisauce";
import { CancelToken, CancelTokenSource } from "axios";

import { useStores } from "@packages/store/models";

export type FetchCallback = (token?: CancelToken) => Promise<any>;

export const useCancelableFetch = (defaultLoading = false) => {
  const store = useStores();
  const [loading, setLoading] = useState(defaultLoading);
  const [error, setError] = useState<any | null>(null);
  const source = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => source.current?.cancel();
  }, []);

  const fetch = useCallback(
    async (callback: FetchCallback) => {
      source.current?.cancel();

      source.current = CancelTokenGenerator.source();

      const { token } = source.current;

      setLoading(true);
      setError(null);

      try {
        const response = await store.fetchApi(callback, true, token);

        // Прерывание - значит никаких манипуляций с состоянием делать больше не нужно
        if (response.kind === "cancel") {
          return null;
        }

        if (!response) {
          throw new Error("loading error");
        }

        setLoading(false);

        return response;
      } catch (err) {
        setError(err);
        setLoading(false);

        return null;
      }
    },
    // Тут обязательно обращаться через store.* иначе useCallback не реагирует
    // Язык тут нужен чтобы перезапрашивать данные после его смены
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [store.fetchApi, store.appLanguage]
  );

  return { fetch, loading, error, source: source.current } as const;
};
