import {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ExerciseResultCorrection } from "@packages/data/creativeTasks";

import styles from "./Answer.module.scss";
import { AnswerTextContent } from "./AnswerTextContent";
import { Text } from "../../Text";
import { CorrectedWord } from "../CorrectedWord";
import { CorrectionContextMenu } from "../CorrectionContextMenu/CorrectionContextMenu";
import { EMPTY_WORD_INDEX } from "../utils/constants";
import {
  CorrectionCreateContext,
  HoverContext,
  TaskContext,
} from "../utils/context";
import { getWordChunks, getWordsFromText } from "../utils/helpers";
import { WordSpace } from "../WordSpace";

interface AnswerTextStudentProps {
  text?: string;
}

export const AnswerTextStudent = (props: AnswerTextStudentProps) => {
  const { text = "" } = props;

  const [currentEditableText, setCurrentEditableText] = useState("");

  const {
    hasErrors,
    errors = [],
    corrections = [],
    setCorrections,
  } = useContext(TaskContext);

  const { setHoveredWordIndex } = useContext(HoverContext);
  const { editableCorrection, setEditableCorrection } = useContext(
    CorrectionCreateContext
  );

  const editableContainerRef = useRef<HTMLElement>(null);

  const words = useMemo(() => getWordsFromText(text), [text]);

  const handleCorrectionEdit = useCallback(
    (correction: ExerciseResultCorrection) => () => {
      if (!hasErrors) {
        return;
      }
      setEditableCorrection?.(correction);
      setCurrentEditableText(correction.text);
      setHoveredWordIndex?.(EMPTY_WORD_INDEX);
    },
    [
      hasErrors,
      setEditableCorrection,
      setCurrentEditableText,
      setHoveredWordIndex,
    ]
  );

  const handleCancelEdit = useCallback(() => {
    setEditableCorrection?.(undefined);
  }, [setEditableCorrection]);

  const handleCorrectionSave = useCallback(
    (correction: ExerciseResultCorrection) => () => {
      setCorrections?.([
        ...corrections,
        {
          ...correction,
          text: currentEditableText,
        },
      ]);
      setEditableCorrection?.(undefined);
    },
    [setCorrections, setEditableCorrection, currentEditableText, corrections]
  );

  const handleEditableChunkInput = useCallback(
    (event: FormEvent<HTMLSpanElement>) => {
      const target = event.target as HTMLSpanElement;
      setCurrentEditableText(target.textContent ?? "");
    },
    [setCurrentEditableText]
  );

  const handleEditedWordClear = useCallback(
    (word: ExerciseResultCorrection) => () => {
      setCorrections?.(corrections.filter((item) => item !== word));
    },
    [setCorrections, corrections]
  );

  const wordChunks = useMemo(() => {
    return getWordChunks(words, errors);
  }, [words, errors]);

  const renderWord = useCallback(
    (correction: ExerciseResultCorrection) => (word: string) =>
      (
        <span onMouseDown={handleCorrectionEdit(correction)}>
          {word}
          <WordSpace />
        </span>
      ),
    [handleCorrectionEdit]
  );

  useEffect(() => {
    const editableContainer = editableContainerRef.current;
    if (!editableCorrection || !editableContainer) {
      return;
    }

    const mouseDownListener = (event: Event) => {
      const target = event.target as HTMLElement;
      if (editableContainer.contains(target)) {
        return;
      }
      setEditableCorrection?.(undefined);
    };

    requestAnimationFrame(() => {
      window.addEventListener("mousedown", mouseDownListener);
    });

    return () => window.removeEventListener("mousedown", mouseDownListener);
  }, [setEditableCorrection, editableContainerRef, editableCorrection]);

  const Texts = useMemo(
    () =>
      wordChunks.map((correction, index) => {
        const isEditable = correction === editableCorrection;
        const { currentId, text, startPosition } = correction;

        const currentWords = getWordsFromText(text);

        const matchedCorrection = corrections?.find(
          ({ startPosition }) => startPosition === correction.startPosition
        );

        const isLastChunk = index === wordChunks.length - 1;

        if (matchedCorrection) {
          return (
            <CorrectedWord
              key={`student_text_word_${currentId}_${index}`}
              text={matchedCorrection.text}
              onClear={handleEditedWordClear(matchedCorrection)}
            />
          );
        }

        if (isEditable) {
          return (
            <span
              key={`student_text_word_${currentId}_${index}`}
              ref={editableContainerRef}
              className={styles["editable-word-container"]}
            >
              <span
                contentEditable
                suppressContentEditableWarning
                onInput={handleEditableChunkInput}
                className={styles["editable-word"]}
              >
                {text}
              </span>
              {!isLastChunk && <WordSpace />}
              <CorrectionContextMenu
                onCancel={handleCancelEdit}
                onSave={handleCorrectionSave(correction)}
              />
            </span>
          );
        }

        return (
          <Text
            key={`student_text_word_${currentId}_${index}`}
            variant="text-2-regular"
            color="base-d"
          >
            <AnswerTextContent
              startIndex={startPosition}
              words={currentWords}
              renderWord={renderWord(correction)}
            />
          </Text>
        );
      }),
    [
      corrections,
      wordChunks,
      editableCorrection,
      handleCorrectionSave,
      handleCorrectionEdit,
      handleEditedWordClear,
    ]
  );

  return <>{Texts}</>;
};
