import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import {
  getCreativeTasks,
  getCreativeTask,
  updateCreativeTask,
  GetCreativeTasksParams,
  GetCreativeTaskParams,
} from "./index";

export const useGetCreativeTasks = (params: GetCreativeTasksParams) => {
  return useQuery({
    queryKey: ["creative-tasks", ...Object.values(params)],
    queryFn: () => getCreativeTasks(params),
  });
};

export const useGetCreativeTask = (
  params: GetCreativeTaskParams,
  options?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof getCreativeTask>>>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery({
    queryKey: ["creative-task", ...Object.values(params)],
    queryFn: () => getCreativeTask(params),
    ...options,
  });
};

export const useUpdateCreativeTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCreativeTask,
    onSuccess: (_, { id, role }) => {
      queryClient.invalidateQueries({
        queryKey: ["creative-task", ...Object.values({ id, role })],
      });
    },
    retry: 3,
  });
};
