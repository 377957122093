const pt = {
  Classes: {
    SampleByStatus: "Amostragem por status das aulas no período",
    OnlyLessonsWithoutGroup: "Apenas aulas sem grupos",
    Title: "Aulas",
    Company: "Empresa",
    Group: "Grupo",
    Status: "Status",
    ClassIndex: "Aula",
    ClassDate: "Data da aula",
    Teacher: "Professor",
    None: "Nada selecionado",
    Process: "Processar",
    DateRangeError: "Selecione a data inicial e final para processar.",
    NoDataError: "Nenhum dado para processar.",
    StandardHelper: "Use pelo menos um filtro para exibir dados.",
    ProcessHelper: "Selecione uma data para exibir dados.",
    Processing: "Processando dados",
    Type: "Tipo",
    Unit: "Módulo",
    Duration: "h/a",
  },
  ClassesDownload: {
    Download: "Exportar",
    Classes: "Aulas",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Digite o nome do grupo",
    SelectGroup: "Selecionar grupo",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "Você não possui módulos atribuídos.",
    SecondTextDefault: "Contate seu coordenador pedagógico.",
    FirstTextDistributor: "Nenhum dado para exibir.",
    SecondTextDistributor: "Tente adicionar alguns dados.",
    FirstTextClasses: "Nenhum dado para exibir.",
    SecondTextClasses: "Altere os filtros ou contate o suporte.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Nenhum professor encontrado",
    SelectTeacher: "Selecionar professor",
  },
  Loading: {
    Error: "Erro ao carregar, tente atualizar a página",
  },
  DateRangePicker: {
    StartDate: "Data inicial",
    EndDate: "Data final",
  },
  DatePicker: {
    Today: "Hoje",
    Clear: "Limpar",
    MaskPlaceholder: "DD/MM/AAAA",
  },
  LessonStatuses: {
    planned: "Agendado",
    completed: "Realizado",
    studentLateCancellation: "Cancelamento tardio pelo aluno",
    earlyCanceledByCustomer: "Cancelamento antecipado pelo aluno",
    earlyCanceledBySchool: "Cancelamento antecipado pela escola",
    lateCanceledByCustomer: "Cancelamento tardio pelo aluno",
    lateCanceledBySchool: "Cancelamento tardio pela escola",
    notPerformedToPay: "A pagar",
  },
};

export default pt;
