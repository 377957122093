import { useCallback, useContext, useMemo } from "react";

import cn from "classnames";

import {
  ExerciseErrorType,
  ExerciseResultError,
} from "@packages/data/creativeTasks";

import styles from "./ErrorContextMenu.module.scss";
import { ErrorContextMenuItem } from "./ErrorContextMenuItem";
import {
  ErrorCreateContext,
  HoverContext,
  UserRoleContext,
} from "../utils/context";
import { isInRange } from "../utils/helpers";
import { UserRole } from "../utils/types";

interface ErrorContextMenuProps {
  startIndex?: number;
  errors: ExerciseResultError[];
  words: string[];
}

export const ErrorContextMenu = (props: ErrorContextMenuProps) => {
  const { startIndex = 0, errors, words } = props;

  const { hoveredError, setHoveredError } = useContext(HoverContext);

  const { currentRole } = useContext(UserRoleContext);

  const { setSelectedError, setNewErrorRange, setNewErrorType } =
    useContext(ErrorCreateContext);

  const contentClasses = cn(styles.container);

  const handleErrorHover = useCallback(
    (error: ExerciseResultError) => () => {
      setHoveredError?.(error);
    },
    [errors]
  );

  const handleErrorLeave = useCallback(() => {
    setHoveredError?.(undefined);
  }, []);

  const handleErrorEdit = useCallback(
    (error: ExerciseResultError) => () => {
      const { startPosition, endPosition } = error;
      setNewErrorRange?.({ startPosition, endPosition });
      setSelectedError?.(error);
      setNewErrorType?.(error.errorTypeId as ExerciseErrorType);
    },
    [errors, setNewErrorRange, setSelectedError, setNewErrorType]
  );

  const Errors = useMemo(
    () =>
      errors.map((error, index) => {
        const errorType = error.errorTypeId as ExerciseErrorType;

        const clickHandler =
          currentRole === UserRole.TEACHER ? handleErrorEdit(error) : undefined;

        const classNames =
          errorType === ExerciseErrorType.COMMENT
            ? styles.comment
            : styles.error;

        const title = words
          .filter((_, index) => isInRange(error, index + startIndex))
          .join(" ");
        return (
          <ErrorContextMenuItem
            key={`error_context_item_${error.currentId || index}`}
            containerClassNames={classNames}
            title={title}
            type={error.errorTypeId}
            desc={error.comment ?? ""}
            hovered={error === hoveredError}
            onHover={handleErrorHover(error)}
            onBlur={handleErrorLeave}
            onClick={clickHandler}
          />
        );
      }),
    [
      errors,
      words,
      startIndex,
      hoveredError,
      currentRole,
      handleErrorHover,
      handleErrorLeave,
    ]
  );

  return <div className={contentClasses}>{Errors}</div>;
};
