import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

import { Unit } from "@packages/store/models/Unit/UnitModel";

import { IconWrapper } from "./styledComponents/IconWrapper";
import { wordDeclensionEndings } from "../../helpers/wordDeclensionEndings";
import { StatusType } from "../../types";
import { Icon } from "../Icon";
import { StatusTypography } from "../StatusTypography";

interface UnitViewProps {
  unit: Unit;
  order: number;
  status: StatusType;
  onClick: () => void;
}

export const UnitView = observer(
  ({ unit, status, order, onClick }: UnitViewProps): JSX.Element | null => {
    const { nameWithoutUnit, exercisesCount } = unit;

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconWrapper>
          <Icon type="elementary" sizeSquareIcon={20} />
        </IconWrapper>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StatusTypography
            variant="regularText"
            status={status}
            onClick={onClick}
          >
            {`${order}. ${nameWithoutUnit}`}
          </StatusTypography>

          {exercisesCount && (
            <StatusTypography
              variant="regularText"
              status={status}
              sx={{ mt: -2 }}
            >
              {`${exercisesCount} ${wordDeclensionEndings(
                "exercise",
                exercisesCount
              )}`}
            </StatusTypography>
          )}
        </Box>
      </Box>
    );
  }
);
