import { ReactNode, useState } from "react";

import { Pagination } from "@jetspeak/jetclass-ui-lib";
import cn from "classnames";

import styles from "./List.module.scss";
import { paginate } from "../../utils/helpers";
import { Text } from "../Text";

interface ListProps<T> {
  containerClassName?: string;
  data: T[];
  renderItem: (item: T, index: number) => ReactNode;
  emptyText?: string;
  itemsPerPage?: number;
  listClassName?: string;
  emptyClassName?: string;
  emptyView?: ReactNode;
}

export const List = function List<T>(props: ListProps<T>) {
  const {
    containerClassName,
    data,
    renderItem,
    emptyText,
    itemsPerPage = 10,
    listClassName,
    emptyClassName,
    emptyView,
  } = props;

  const [page, setPage] = useState(1);

  const list = paginate<T>(data, page, itemsPerPage);

  const containerClasses = cn(containerClassName, styles.container);
  const listClasses = cn(listClassName, styles.list);
  const emptyClasses = cn(emptyClassName, styles.empty);

  return (
    <div className={containerClasses}>
      {data.length > 0 && (
        <div className={listClasses}>{list.map(renderItem)}</div>
      )}
      {data.length === 0 &&
        (emptyView ?? (
          <div className={emptyClasses}>
            <Text variant="h2-mob">{emptyText}</Text>
          </div>
        ))}
      <Pagination
        currPage={page}
        total={data.length}
        perPage={itemsPerPage}
        onChange={setPage}
      />
    </div>
  );
};
