import { createContext } from "react";

import {
  ExerciseResultCorrection,
  ExerciseResultError,
} from "@packages/data/creativeTasks";

export interface TaskContextProps {
  hasErrors?: boolean;
  errors?: ExerciseResultError[];
  corrections?: ExerciseResultCorrection[];
  setErrors?: (_: ExerciseResultError[]) => void;
  setCorrections?: (_: ExerciseResultCorrection[]) => void;
}

export const TaskContext = createContext<TaskContextProps>({});
