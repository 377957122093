const pt = {
  AttendancePivotTable: {
    Student: "Estudante",
    Visited: "Comparecido, %",
    Hours: "Quantidade de horas/aula",
    Month: "Mês",
    Lesson: "Aula",
    Date: "Data",
    Duration: "Duração",
    Teacher: "Professor",
    Days: "Dias",
    Time: "Horário",
    Course: "Curso: {{course, string}}",
    GroupSchedule: "Cronograma das aulas do grupo {{group, string}}",
    Total: "Programa de {{hours, number}} horas/aula",
    CompletedPeriod: "Realizado no período: {{hours, number}} horas/aula",
    LeftPeriod: "Restante no final do período: {{hours, number}} horas/aula",
    NotPerformedToPayPeriod:
      "Cancelamentos excedentes: {{hours, number}} horas/aula",
    TotalInTable: "Programa",
    CompletedInTable: "Realizado no período",
    NotPerformedToPayHours: "Cancelamentos excedentes: ",
    LeftInTable: "Restante no final do período",
    MinHours: "h/a",
  },
  ReportsAttendance: {
    Title: "Frequência",
    AllGroups: "Todos os grupos",
    SelectGroup: "Selecionar grupo",
    EmptyGroupAndCompany: "Selecione uma empresa ou grupo para ver o relatório",
    Download: "Exportar",
    AllCompanies: "Todas as empresas",
    SelectCompany: "Selecione a empresa",
    EmptyLessons:
      "Atualmente não há aulas concluídas com os filtros selecionados, portanto não é possível gerar o relatório de frequência",
    OnlyTeachersLessons: "Apenas minhas aulas",
  },
  AttendancePivotReportDownload: {
    Sheet: "Relatório consolidado",
  },
  DatePicker: {
    MaskPlaceholder: "DD/MM/AAAA",
    Today: "Hoje",
    Clear: "Limpar",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Digite o nome do grupo",
    SelectGroup: "Selecionar grupo",
  },
  Loading: {
    Error: "Erro ao carregar, tente atualizar a página",
  },
  ModalGroupReport: {
    Teacher: "Professor",
    Course: "Curso",
    Schedule: "Cronograma",
    Duration: "Duração",
  },
  GroupFilter: {
    SearchStudent: "Buscar aluno",
    StartDate: "Data de início",
    EndDate: "Data de término",
  },
  MonthName: {
    "1": "Janeiro",
    "2": "Fevereiro",
    "3": "Março",
    "4": "Abril",
    "5": "Maio",
    "6": "Junho",
    "7": "Julho",
    "8": "Agosto",
    "9": "Setembro",
    "10": "Outubro",
    "11": "Novembro",
    "12": "Dezembro",
  },
  ShortWeekDay: {
    "0": "DOM",
    "1": "SEG",
    "2": "TER",
    "3": "QUA",
    "4": "QUI",
    "5": "SEX",
    "6": "SÁB",
  },
  Progress: {
    totalProgress: "Progresso geral",
    completed: "Concluído",
    yourProgress: "Seu progresso:",
  },
};

export default pt;
