import { uniq } from "lodash";
import {
  Instance,
  SnapshotOut,
  types,
  getRoot,
  cast,
  resolveIdentifier,
} from "mobx-state-tree";

import {
  LearningGroup,
  LearningGroupModel,
  LearningGroupSnapshot,
} from "./LearningGroupModel";
import { ClassGroup } from "../ClassGroup/ClassGroup";
import { Course } from "../Course/CourseModel";
import { withEnvironment } from "../extensions/withEnvironment";
import { Lesson } from "../Lesson/LessonModel";
import { RootStore } from "../RootStore/RootStore";

export const findClassGroup = (
  data: LearningGroup[],
  id: string
): ClassGroup | undefined => {
  let classGroup: ClassGroup | undefined;

  data.forEach((i) => {
    if (i.classGroups) {
      classGroup = i.classGroups?.find((j) => String(j?.course?.id) === id);
    }
  });

  return classGroup;
};

export const LearningGroupStoreModel = types
  .model("LearningGroup")
  .props({
    items: types.optional(types.array(LearningGroupModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: LearningGroupSnapshot) => {
      const instance = resolveIdentifier(LearningGroupModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
    clear() {
      self.items = cast([]);
    },
  }))
  .views((self) => ({
    get activeGroups(): LearningGroup[] {
      return self.items.filter((g) => g.isActive);
    },
    get countLessons() {
      let count = 0;

      self.items.forEach((group) => {
        count += group.lessons.length;
      });

      return count;
    },
    get activeCourses(): Course[] {
      const { course } = getRoot<RootStore>(self);
      return uniq(
        self.items
          .filter(({ isActive }) => isActive)
          .flatMap((group) => course.getCoursesByGroup(group))
      );
    },
    get activeLessons(): Lesson[] {
      return self.items.filter((g) => g.isActive).flatMap((g) => g.lessons);
    },
    getProgressUnits(course: Course) {
      const classGroup = findClassGroup(self.items, course.id);
      if (classGroup) {
        const countUnits = course.getUnits.length || 0;
        const lessonsPerUnit = classGroup.lessonsPerUnit || 0;
        let totalCompleted = 0;

        classGroup.classSchedules.forEach((schedule) => {
          schedule.lessons.forEach((lesson) => {
            if (lesson.currentStatus === "completed") {
              totalCompleted += 1;
            }
          });
        });

        return {
          total: countUnits,
          completed: totalCompleted / lessonsPerUnit,
        };
      }

      return { total: 0, completed: 0 };
    },
    getProgressClasses(course: Course) {
      const completedStatuses: string[] = [
        "completed",
        "lateCanceledByCustomer",
        "lateCanceledBySchool",
      ];

      const classGroup = findClassGroup(self.items, course.id);

      let completed = 0;
      let total = 0;

      classGroup?.classSchedules.forEach((schedule) => {
        schedule.lessons.forEach(({ currentStatus }) => {
          if (currentStatus && completedStatuses.includes(currentStatus)) {
            completed += 1;
          } else if (currentStatus !== "earlyCanceledBySchool") {
            total += 1;
          }
        });
      });

      return { completed, total };
    },
    getById(id: string) {
      return self.items.find((group) => group.id === id);
    },
  }));

type LearningGroupStoreType = Instance<typeof LearningGroupStoreModel>;
export type LearningGroupStore = LearningGroupStoreType;
type LearningGroupStoreSnapshotType = SnapshotOut<
  typeof LearningGroupStoreModel
>;
export type LearningGroupStoreSnapshot = LearningGroupStoreSnapshotType;
