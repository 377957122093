import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { UnitModel, UnitSnapshot, Unit } from "./UnitModel";
import { withEnvironment } from "../extensions/withEnvironment";

export const UnitStoreModel = types
  .model("UnitStore")
  .props({
    items: types.optional(types.array(UnitModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: UnitSnapshot) => {
      const instance = resolveIdentifier(UnitModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .views((self) => ({
    getUnit(id: string | undefined): Unit | undefined {
      return self.items.find((item) => item.id === id);
    },
  }));

type UnitStoreType = Instance<typeof UnitStoreModel>;
export type UnitStore = UnitStoreType;
type UnitStoreSnapshotType = SnapshotOut<typeof UnitStoreModel>;
export type UnitStoreSnapshot = UnitStoreSnapshotType;
export const createUnitStoreDefaultModel = () =>
  types.optional(UnitStoreModel, {} as UnitStoreSnapshot);
