import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import styles from "./GroupStatistics.module.scss";
import { Icon } from "../../components/Icon";

export interface GroupStatisticsProps {
  groupName?: string;
  classPeriod?: string | null;
  teacherName?: string;
  courseName?: string;
  schedule?: string[];
  totalHours?: number;
  completedHours?: number;
  notPerformedToPayHours: number;
  leftHours?: number;
}

export const GroupStatistics = ({
  groupName = "",
  classPeriod = "",
  teacherName = "",
  courseName = "",
  schedule = [],
  totalHours = 0,
  completedHours = 0,
  notPerformedToPayHours,
  leftHours = 0,
}: GroupStatisticsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.GroupStatistics}>
      <div className={styles.GroupStatistics__col}>
        <Icon
          type="groupReportPresentation"
          color="#333"
          width={34}
          height={34}
        />
        <Typography fontSize="1.1rem" fontWeight="700" color="#333">
          {groupName}
        </Typography>
      </div>
      <div className={styles.GroupStatistics__col}>
        <Typography fontSize="0.8rem">
          {t("ModalGroupReport:Teacher")}: {teacherName}
        </Typography>
        <Typography fontSize="0.8rem">
          {t("ModalGroupReport:Course")}:{" "}
          <Typography
            display="inline"
            fontSize="0.8rem"
            className={styles.GroupStatistics__accent}
          >
            {courseName}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("ModalGroupReport:Schedule")}:{" "}
          <Typography
            display="inline"
            fontSize="0.8rem"
            className={styles.GroupStatistics__accent}
          >
            {schedule.join(", ")}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("ModalGroupReport:Duration")}:{" "}
          <Typography
            display="inline"
            fontSize="0.8rem"
            className={styles.GroupStatistics__accent}
          >
            {classPeriod}
          </Typography>
        </Typography>
      </div>
      <div className={styles.GroupStatistics__col}>
        <Typography fontSize="0.8rem">
          {t("AttendancePivotTable:TotalInTable")}:{" "}
          <Typography
            display="inline"
            fontSize="0.8rem"
            className={styles.GroupStatistics__accent}
            fontWeight={500}
          >
            {totalHours.toFixed(2)} {t("AttendancePivotTable:MinHours")}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("AttendancePivotTable:CompletedInTable")}:{" "}
          <Typography
            display="inline"
            fontSize="0.8rem"
            className={styles.GroupStatistics__accent}
          >
            {completedHours.toFixed(2)} {t("AttendancePivotTable:MinHours")}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("AttendancePivotTable:NotPerformedToPayHours")}
          <Typography
            display="inline"
            fontSize="0.8rem"
            className={styles.GroupStatistics__accent}
          >
            {notPerformedToPayHours.toFixed(2)}{" "}
            {t("AttendancePivotTable:MinHours")}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("AttendancePivotTable:LeftInTable")}:{" "}
          <Typography
            display="inline"
            fontSize="0.8rem"
            className={styles.GroupStatistics__accent}
          >
            {leftHours.toFixed(2)} {t("AttendancePivotTable:MinHours")}
          </Typography>
        </Typography>
      </div>
    </div>
  );
};
