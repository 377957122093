import { useCallback, useContext, useState } from "react";

import cn from "classnames";
import { observer } from "mobx-react-lite";

import {
  ExerciseErrorType,
  ExerciseResultError,
} from "@packages/data/creativeTasks";

import styles from "./ErrorPreivews.module.scss";
import { Icon } from "../../Icon";
import { HoverContext, TaskContext, UserRoleContext } from "../utils/context";
import { getErrorIcon } from "../utils/helpers";
import { UserRole } from "../utils/types";

interface ErrorPreivewsItemProps {
  error: ExerciseResultError;
}

export const ErrorPreivewsItem = observer((props: ErrorPreivewsItemProps) => {
  const { error } = props;

  const { currentRole } = useContext(UserRoleContext);
  const { errors = [], setErrors } = useContext(TaskContext);
  const { setHoveredError } = useContext(HoverContext);

  const [isHovered, setIsHovered] = useState(false);

  const containerClasses = cn(styles.item, {
    [styles.hovered]: isHovered,
    [styles.comment]: error.errorTypeId === ExerciseErrorType.COMMENT,
  });

  const handleErrorRemove = useCallback(() => {
    if (!isHovered) {
      return;
    }
    setErrors?.(errors.filter((item) => item !== error));
  }, [isHovered, errors, error, setErrors]);

  const handleErrorHover = useCallback(
    (error: ExerciseResultError) => () => {
      setHoveredError?.(error);
      if (currentRole === UserRole.TEACHER) {
        setIsHovered(true);
      }
    },
    [setHoveredError, setIsHovered, currentRole]
  );

  const handleErrorLeave = useCallback(() => {
    setHoveredError?.(undefined);
    setIsHovered(false);
  }, [setHoveredError, setIsHovered]);

  const icon = getErrorIcon(error.errorTypeId);

  return (
    <div
      className={containerClasses}
      onMouseEnter={handleErrorHover(error)}
      onMouseLeave={handleErrorLeave}
      onClick={handleErrorRemove}
    >
      {isHovered && <Icon icon="close-filters" />}
      {!isHovered && <Icon icon={icon} />}
    </div>
  );
});
